import React from 'react'
import { Button } from '@material-ui/core'
import styles from './authForm.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { submitAsyncValidation } from '../../_utils/forms'
import { connectTo } from '../../_utils/generic';

const authform = ({ handleSubmit,
  enabledSubmit, onSubmit, submitText, fields }) => {
  return (
      <div className={styles.container}>
        <form onSubmit={submitAsyncValidation(handleSubmit, enabledSubmit, onSubmit)}>
          <div className={styles.header}>

          </div>

          <div className={styles.inputs}>
            {fields}
          </div>

          <div className={styles.actions}>
            <div className={styles.btn}>
              <Button variant="contained" color="primary" type="submit" className={styles.button} disabled={!enabledSubmit}>{submitText}</Button>
            </div>
          </div>
        </form>
      </div>
  )
};

export default withStyles(styles)(connectTo(
  state => ({
  }),
  {},
  authform)
)

