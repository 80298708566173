import React from 'react'
import { Provider } from 'react-redux'
import './_utils/array-extensions'
import store from './store'
import saga from './_sagas'
import Routes from './Routes'
import { sagaMiddleware } from './middleware'
import { receiveMockState } from './_actions/mock'
import { loggedIn } from './_utils/auth'
import { startApp } from './_actions/generic'

import RTL from './_styles/RTL';
import theme from './_styles/theme';

import StartPage from './StartPage/StartPage';
import { connectTo } from './_utils/generic';
import { ThemeProvider } from '@material-ui/core/styles';

const Runner = connectTo(
  state => ({
    appStarted: state.generic.appStarted
  }),
  {},
  (({ appStarted }) => {
    return (
      <>
        {appStarted || !loggedIn() ? <Routes /> : <StartPage />}
      </>
    )
  })
)

const LanguageSwitcher = connectTo(
  state => ({
    language: state.generic.language
  }),
  {},
  (({ language, children }) => {
    if (language === 'fa')
      return (
        <RTL>
          <ThemeProvider theme={theme(language)} >
            {children}
          </ThemeProvider>
        </RTL>
      )
    else
      return (
        <ThemeProvider theme={theme(language)} >
          {children}
        </ThemeProvider>
      )
  })
)


const App = () => {
  return (
    <Provider store={store}>
      <LanguageSwitcher>
        <Runner></Runner>
      </LanguageSwitcher>
    </Provider>
  )
}

export default App

sagaMiddleware.run(saga)

loggedIn() && store.dispatch(startApp())

if (process.env.REACT_APP_MOCK) {
  import('./_mocks/state.js').then(module => {
    const state = store.getState()
    store.dispatch(
      receiveMockState(
        Object.entries(state).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: { ...value, ...module.MOCK_STATE[key] }
          }),
          {}
        )
      )
    )
  })
}
