import { put, call, take, spawn, delay } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga';
import { appStarted, toggleSnackbar } from '../_actions/generic';
import { NOTIFICATIONS } from '../_constants/api';
import * as signalR from '@aspnet/signalr'

function* listenNotifications() {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(NOTIFICATIONS, { accessTokenFactory: () => localStorage.token })
    .build()

  let attempt = 0
  let connected = false
  while (attempt < 10 && !connected) {
    attempt++
    connected = true
    try {
      yield call(() => connection.start())
      console.info('SignalR: successfully connected')
    } catch (err) {
      console.info(`SignalR: attempt ${attempt}: failed to connect`)
      yield delay(1000)
      connected = false
    }
  }

  if (connected) {
    const getEventChannel = connection => eventChannel(emit => {
      const handler = data => { emit(data) }
      connection.on('notification', handler)
      return () => { connection.off() }
    })

    const channel = yield call(getEventChannel, connection)
    while (true) {
      const { notificationType, payload } = yield take(channel)

      if (['LIKE', 'UNLIKE'].includes(notificationType)) {
        const message = `${payload.username} ${notificationType.toLowerCase()}d "${payload.storyTitle}"`
        yield put(toggleSnackbar(message))
      }

    }
  }
}

export function* startApp() {
  window.history.pushState({}, '', '')
  if (false)
    yield spawn(listenNotifications)

  // function* ticking() {
  //   yield put(tickAction())
  //   yield delay(TICK)
  //   yield* ticking()
  // }
  // yield* ticking()


  yield put(appStarted())
}