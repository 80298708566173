import {
  createLogger
} from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { unauthorizeUser } from './_actions/auth'

const logger = createLogger({
  predicate: (getState, action) => false
});

export const sagaMiddleware = createSagaMiddleware()

const localStorageMiddleware = store => next => action => {
  if (action.type === unauthorizeUser.getType()) {
    localStorage.clear()
  }

  const prevState = store.getState()
  const result = next(action)
  const nextState = store.getState()

  // if (action.type === removeFromBasket.getType() ||
  //   action.type === addToBasket.getType() ||
  //   action.type === addRangeToBasket.getType() ||
  //   action.type === clearFromBasket.getType() ||
  //   action.type === changeToBasket.getType() ||
  //   action.type === Reset.getType() ||
  //   action.type === resetBasket.getType()) {
  //   localStorage.setItem('basket', JSON.stringify(nextState.baseinfo.BasketList))
  // }

  if (prevState.auth.Token !== nextState.auth.Token && nextState.auth.Token) {
    localStorage.setItem('ID', nextState.auth.ID)
    localStorage.setItem('FullName', nextState.auth.FullName)
    localStorage.setItem('Token', nextState.auth.Token)
    localStorage.setItem('TokenExpirationTime', nextState.auth.TokenExpirationTime)
    localStorage.setItem('Username', nextState.auth.Username)
    localStorage.setItem('IDAgency', nextState.auth.IDAgency)
    localStorage.setItem('AgencyList', JSON.stringify(nextState.auth.AgencyList))
  }

  if (prevState.auth.IDAgency !== nextState.auth.IDAgency && nextState.auth.IDAgency) {
    localStorage.setItem('IDAgency', nextState.auth.IDAgency)
  }

  if (prevState.auth.modelConfig !== nextState.auth.modelConfig && nextState.auth.modelConfig) {
    localStorage.setItem('modelConfig', JSON.stringify(nextState.auth.modelConfig))
  }

  if (prevState.auth.AppSetting !== nextState.auth.AppSetting && nextState.auth.AppSetting) {
    localStorage.setItem('AppSetting', JSON.stringify(nextState.auth.AppSetting))
  }
  return result
}

export default [sagaMiddleware, localStorageMiddleware, logger]
