import React, { PureComponent } from 'react'
import { connectTo, numberWithCommas } from '../_utils/generic';
import styles from './home.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { to } from '../_actions/navigation'
import { openLoading, closeLoading } from '../_actions/loading';
import { loadDialog } from '../_actions/dialog';
import { toggleSnackbar } from '../_actions/generic';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ReactHtmlParser from 'react-html-parser';

import {
  setShowTizer,
  setShowTopSlider,
  setShowWellcomeSlider,
  setShowTouch,
  setShowSelectType,
  setShowTouchTypeContainer,
  setShowIdentity,
  setShowStartContainer,
  setShowProductCartContainer,
  setShowProduct,
  setShowCart,
  setShowPager,
  setShowPagerContainer,
  getByTel,
  insertByTel,
  receiveUserInfo,
  setShowContact,
  setShowContactContainer,

  setShowRating,
  setShowRatingContainer,
  getByIdInvoice,
  getByInvoiceNumber,
  getByOrderNo,
  receiveInvoiceList,
  getCommentSample,
  receiveCommentSample,
  changeRating,
  insertRating,

  setShowPaymentType,
  setShowPaymentTypeContainer,
  setShowCardStep,
  setShowCardStepContainer,
  setShowFactor,
  setShowFactorContainer,
  setShowGroups,
  setShowProducts,
  setShowProductsGroupsContainer,
  selectType,
  getProduct,
  getProducts,
  getProductgroup,
  GetDailyProducts,
  changeToBasket,
  addToBasket,
  addRangeToBasket,
  removeFromBasket,
  clearFromBasket,
  resetBasket,
  getBasket,
  receiveBasket,
  addSelectedProduct,
  removeSelectedProduct,
  backToProductList,
  InvoiceInsert,
  Reset,
  ResetDone,
  ResetTimer,
  sleepChecker,
  signout
} from '../_actions/baseInfo/baseinfo';

import { changeLanguage } from '../_actions/generic'

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DoneIcon from '@material-ui/icons/Done';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import cardAnimation from '../_assets/images/cardAnimation.gif';
import card from '../_assets/images/card.jpg';
import cash from '../_assets/images/cash.jpg';
import food from '../_assets/images/food.jpg';
import cart from '../_assets/images/cart.png';
import touch from '../_assets/images/touch.png';
import survey from '../_assets/images/survey.svg';
import zh from '../_assets/images/zh.png';
import salon from '../_assets/images/salon.svg';
import takeaway from '../_assets/images/takeaway.svg';
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Fab, Button, FormControl, Input, Table, TableHead, TableRow, TableCell, TableBody, TextField, OutlinedInput, InputAdornment, RadioGroup, FormControlLabel, Radio, Switch } from '@material-ui/core';
import fadeTransition from "../_styles/transitions/fade.module.scss";
import slideToRightTransition from "../_styles/transitions/slidetoright.module.scss";
import slideToLeftTransition from "../_styles/transitions/slidetoleft.module.scss";
import slideToUpTransition from "../_styles/transitions/slidetoUp.module.scss";
import slideToDownTransition from "../_styles/transitions/slidetoDown.module.scss";
import KeyboardReact from 'react-simple-keyboard';
import { MEDIAFILE } from '../_constants/api';
import ReactToPrint from 'react-to-print';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Rating from 'material-ui-rating';
import { Translate, WordsEnum } from '../_utils/language';

const speedSlide = 800;

const numpad = {
  default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 _"],
  shift: ["! / #", "$ % ^", "& * (", "{bksp} ) +"]
};

const persian = {
  'default': [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} ض ص ث ق ف غ ع ه خ ح ج چ پ",
    "{lock} ش س ی ب ل ا ت ن م ک گ {enter}",
    "{shift} ظ ط ز ژ ر ذ د ئ و . / {shift}",
    '.com @ {space}'
  ],
  shift: [
    "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
    "{tab} \u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
    '{lock} \u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " {enter}',
    "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F {shift}",
    ".com @ {space}"
  ]
};

const english = {
  default: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
    ".com @ {space}"
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
    ".com @ {space}"
  ]
};



const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props} />
  );
});

export const ActionTableCell = withStyles(theme => ({
  root: {
    padding: '0px 5px !importante',
  },
  head: {
    backgroundColor: "#cbced3",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell = withStyles(theme => ({
  head: {
  },
  body: {
    borderBottom: 0,
    padding: 0,
    fontSize: 20,
  },
}))(TableCell);

const StyledNumberTableCell = withStyles(theme => ({
  head: {
  },
  body: {
    borderBottom: 0,
    padding: 0,
    fontSize: 18,
    fontFamily: "\"Helvetica\", \"Arial\", sans-serif",
  },
}))(TableCell);

const initState = {
  showWellcome: true,
  showWellcomeSlider: true,
  showSelectType: false,
  showSlider: false,
  showIdentity: true,
  showGroupSelector: false,
  showProducts: false,
  showProduct: false,

  pager: '',
  layoutNamePager: "default",
  mediaScreen: "",
  moreDetails: false,
  showKeyPadFooter: false,
  showKeyPadFooterContainer: false,
  produstExplanation: "",
  layoutNameFooter: "default",
  languageFooter: "fa",

  layoutNameUserMobile: "default",
  userMobile: '',

  layoutNameInvoiceNumber: "default",
  invoiceNumber: '',

  ratingInvoiceType: 3,
  discountProduct: false
}

class Home extends PureComponent {
  constructor(props) {
    super(props)

    this.state = initState

    window.addEventListener('resize', this.updateWindowDimensions)
  }

  translate(word) {
    const { language } = this.props

    return Translate(word, language)
  }

  switchTranslate(fa, en) {
    const { language } = this.props;
    if (language === "fa")
      return fa;
    else
      return en;
  }

  componentDidMount() {
    const { GetDailyProducts, sleepChecker } = this.props
    GetDailyProducts();
    sleepChecker();
    this.updateWindowDimensions();
  }

  updateWindowDimensions = () => {
    const mediaScreen = window.innerWidth < 600 ? "xs" : window.innerWidth < 720 ? "sm" : window.innerWidth < 960 ? "lgk" : window.innerWidth < 1280 ? "md" : window.innerWidth < 1920 ? "lg" : "xl"
    this.setState({ mediaScreen: mediaScreen })
  }

  RenderWellcome = () => {
    const { setShowTouch, ResetDone, setShowRatingContainer } = this.props;
    const { mediaScreen, ...rest } = initState
    this.setState(rest);
    return (
      <div className={styles.wellcome}>
        <div className={styles.ratingAction} onClick={() => {
          setShowRatingContainer(true);
          setShowTouch(false);
          ResetDone();
        }}>
          <img src={survey} alt="" ></img>
          {this.translate(WordsEnum.Poll)}
        </div>
        <div className={styles.touch} >
          <img src={touch} alt="" onClick={() => {
            setShowTouch(false);
            ResetDone();
          }}></img>
          <div onClick={() => {
            setShowTouch(false);
            ResetDone();
          }}>
            {this.translate(WordsEnum.TouchToPlaceAnOrder)}
          </div>
        </div>
      </div>
    )

  }

  DailyProductSlider = () => {
    const { dailyProducts, AppSetting } = this.props
    const { mediaScreen } = this.state

    let rows = mediaScreen === "xs" ? 3 : mediaScreen === "sm" ? 4 : mediaScreen === "md" ? 5 : mediaScreen === "lg" ? 6 : 7
    const settings = {
      dots: false,
      infinite: true,
      speed: AppSetting.SliderDelay * 1000,
      slidesToShow: dailyProducts.filter(f => f.IDPicture !== null).length < rows ? dailyProducts.filter(f => f.IDPicture !== null).length : rows,
      slidesToScroll: 4,
      autoplay: true,
      arrows: false,
    }

    return <Slider {...settings} className={styles.slider}>
      {
        dailyProducts.filter(f => f.IDPicture !== null).map((item, index) => (
          <div className={styles.sliderItem} key={index}>
            <img className={styles.img} src={item.IDPicture ? MEDIAFILE(item.IDPicture) : food} alt=""></img>
          </div>
        ))
      }
    </Slider>
  }

  SelectType = () => {
    const { setShowSelectType, selectType } = this.props;

    return (
      <div className={styles.selecttakeaway}>
        <div onClick={() => { selectType({ idOrderType: 2 }); setShowSelectType(false); }}>
          <span>بیرون بر</span>
          <span>Take Away</span>
          <img src={takeaway} alt=""></img>
        </div>

        <div onClick={() => { selectType({ idOrderType: 1 }); setShowSelectType(false) }}>
          <span>سالن</span>
          <span>Salon</span>
          <img src={salon} alt=""></img>
        </div>
      </div>
    )
  }

  ProductSelector = ({ row }) => {
    const { getProduct, addToBasket, removeFromBasket, BasketList, products: allproducts, AppSetting, ResetTimer } = this.props;
    const { mediaScreen } = this.state
    let rows = mediaScreen === "xs" ? 2 : mediaScreen === "sm" ? 4 : mediaScreen === "lgk" ? 4 : mediaScreen === "md" ? 4 : mediaScreen === "lg" ? 5 : 6
    const products = allproducts.filter(f => (f.DiscountPercent > 0 && this.state.discountProduct) || !this.state.discountProduct)
    const currentProducts = products.slice(row === 2 ? 0 : products.length / 2, row === 2 ? products.length / 2 : products.length)

    const settings = {
      dots: false,
      speed: AppSetting.SliderDelay * 1000,
      slidesToShow: currentProducts.length < rows ? currentProducts.length : rows,
      slidesToScroll: 4,
      autoplay: false,
      arrows: false
    }

    return (
      <div className={styles.SelectProduct}>
        <div className={styles.navleft}>{currentProducts.length > 0 &&
          <NavigateBeforeIcon fontSize="large" onClick={() => {
            ResetTimer();
            if (row === 1) {
              this.groupSlider1.slickNext()
              this.groupSlider2.slickPrev()
            }
            else {
              this.groupSlider2.slickNext()
              this.groupSlider1.slickPrev()
            }
          }} />
        }
        </div>
        <Slider {...settings}
          ref={slider => {
            if (row === 1)
              return (this.groupSlider1 = slider)
            else
              return (this.groupSlider2 = slider)
          }}
          className={styles.productSlider}>
          {
            currentProducts.map((item, index) => {
              var inBasket = BasketList.filter(f => f.ID === item.ID)

              return (
                <div key={index} >
                  <div className={styles.card}>
                    <div className={styles.name} onClick={() => {
                      this.setState({
                        produstExplanation: inBasket.length === 0 ? "" : inBasket[0].Comment
                      });
                      getProduct({ productId: item.ID })
                    }}>
                      {this.switchTranslate(item.ProductName, item.LatinName)}
                    </div>
                    <div className={styles.img} onClick={() => {
                      this.setState({
                        produstExplanation: inBasket.length === 0 ? "" : inBasket[0].Comment
                      });
                      getProduct({ productId: item.ID })
                    }}>
                      <img className={styles.img} src={item.IDPicture ? MEDIAFILE(item.IDPicture) : food} alt=""></img>
                    </div>
                    <div className={styles.prices} onClick={() => {
                      this.setState({
                        produstExplanation: inBasket.length === 0 ? "" : inBasket[0].Comment
                      });
                      getProduct({ productId: item.ID })
                    }}>
                      {item.DiscountPercent > 0 && <span className={styles.percentage}>{item.DiscountPercent}</span>}
                      <span className={styles.price}>
                        <span>{numberWithCommas(item.PriceAfterDiscount)}</span>
                        <span>{AppSetting.CurrencySymbol}</span>
                      </span>
                      {item.DiscountPercent > 0 && <span className={styles.mainprice}>{numberWithCommas(item.UnitPrice)}</span>}
                    </div>
                    <div className={styles.cnt}>
                      <Fab size="small" color="secondary" className={styles.btn} aria-label="add" onClick={() => addToBasket(item)}>
                        <AddIcon />
                      </Fab>
                      <span className={inBasket.length > 0 && inBasket[0].count > 0 ? styles.active : ""}>
                        {inBasket.length === 0 ? 0 : inBasket[0].count}
                      </span>
                      {
                        <Fab size="small" color="secondary" className={styles.btn} aria-label="remove" disabled={inBasket.length === 0} onClick={() => removeFromBasket(item)}>
                          <RemoveIcon />
                        </Fab>
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Slider>
        <div className={styles.navright}>
          {
            currentProducts.length > 0 &&
            <NavigateNextIcon fontSize="large" onClick={() => {
              ResetTimer();
              if (row === 1) {
                this.groupSlider1.slickPrev()
                this.groupSlider2.slickNext()
              }
              else {
                this.groupSlider2.slickPrev()
                this.groupSlider1.slickNext()
              }
            }} />
          }
        </div>
      </div>)
  }

  Product = () => {
    const { addToBasket, removeFromBasket, BasketList, products, allProducts, selectedProduct, AppSetting } = this.props;
    const item = products.filter(f => f.ID === selectedProduct)[0]
    const { mediaScreen } = this.state
    var inBasket = BasketList.filter(f => f.ID === selectedProduct)
    var currentProducts = allProducts.filter(f => item.RelatedList.filter(ff => ff === f.ID).length > 0)
    let rows = mediaScreen === "xs" ? 2 : mediaScreen === "sm" ? 3 : mediaScreen === "lgk" ? 4 : mediaScreen === "md" ? 4 : mediaScreen === "lg" ? 4 : 4

    var settingsRelated = {
      dots: false,
      speed: AppSetting.SliderDelay * 1000,
      slidesToShow: currentProducts.length < rows ? currentProducts.length : rows,
      slidesToScroll: 3,
      autoplay: false,
      arrows: false
    }

    var settingsExplanation = {
      dots: false,
      speed: AppSetting.SliderDelay * 1000,
      slidesToShow: item.ExplanationList.length < rows ? item.ExplanationList.length : rows,
      slidesToScroll: 4,
      autoplay: false,
      arrows: false
    }

    return (
      <div className={styles.productShow}>
        <div className={styles.product}>
          <div className={styles.detail}>
            <div className={styles.cnt}>
              <Fab color="secondary" className={styles.btn} aria-label="add" onClick={() => addToBasket(item)}>
                <AddIcon />
              </Fab>
              <span>
                {inBasket.length > 0 ? inBasket[0].count : 0}
              </span>
              <Fab color="secondary" className={styles.btn} aria-label="add" disabled={inBasket.length === 0} onClick={() => removeFromBasket(item)}>
                <RemoveIcon />
              </Fab>
            </div>
            <div className={styles.content}>
              <div className={styles.name}>{this.switchTranslate(item.ProductName, item.LatinName)}</div>
              <div className={styles.content}>
                <div className={styles.text}>
                  <div className={styles.description}>
                    <span>{item.Comment}</span>
                    <span>{this.translate(WordsEnum.Contents)} : {item.Features}</span>
                  </div>
                  {item.Calories > 0 &&
                    <div className={styles.cal}>
                      <span>{item.Calories}</span>
                      <span>{this.translate(WordsEnum.Calories)}</span>
                    </div>
                  }
                </div>
                <div className={styles.img}>
                  <img src={item.IDPicture ? MEDIAFILE(item.IDPicture) : food} alt=""></img>
                </div>
              </div>
              <div className={styles.prices}>
                {item.DiscountPercent > 0 && <span className={styles.mainprice}>{numberWithCommas(item.UnitPrice)}</span>}
                <span className={styles.price}>
                  <span>{numberWithCommas(item.PriceAfterDiscount)}</span>
                  <span>{AppSetting.CurrencySymbol}</span>
                </span>
                {item.DiscountPercent > 0 && <span className={styles.percentage}>{item.DiscountPercent}</span>}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.explanationList}>
              <div className={styles.TextField}>
                <div> {this.translate(WordsEnum.KitchenDescription)} : </div>
                <div>
                  <TextField
                    id="outlined-textarea"
                    autoComplete="off"
                    placeholder={this.translate(WordsEnum.KitchenDescription)}
                    variant="outlined"
                    value={this.state.produstExplanation}
                    onChange={e => this.onChangeInputFooter("produstExplanation", e, item)}
                    onFocus={e => this.onChangeFieldFooter("produstExplanation", true)}
                  />
                </div>
                {
                  this.state.showKeyPadFooter ? <div>
                    <Fab size="small" color="primary" aria-label="add" onClick={() => this.confirmExplanation(item)}>
                      <DoneIcon />
                    </Fab>
                  </div> : null
                }
              </div>
              <div className={styles.slider}>
                <Slider {...settingsExplanation}>
                  {
                    item.ExplanationList.map((itemExplanation, indexExplanation) =>
                      (
                        <div key={indexExplanation}>
                          <Fab
                            variant="extended"
                            size="small"
                            color="primary"
                            aria-label="add"
                            className={styles.item}
                            onClick={() => this.selectExplanation(item, itemExplanation)}
                          >
                            {itemExplanation}
                          </Fab>
                        </div>
                      ))
                  }
                </Slider>
              </div>
            </div>
            <div className={styles.relatedList}>
              <Slider {...settingsRelated}>
                {
                  currentProducts.map((itemRelated, indexRelated) => {
                    var inBasketRelated = BasketList.filter(f => f.ID === itemRelated.ID);
                    return (
                      <div key={indexRelated}>
                        <div className={styles.item}>
                          <div className={styles.info}>
                            <div className={styles.name}>{this.switchTranslate(itemRelated.ProductName, itemRelated.LatinName)}</div>
                            <div className={styles.price}>
                              <span>{numberWithCommas(itemRelated.PriceAfterDiscount)}</span>
                              <span>{AppSetting.CurrencySymbol}</span>
                            </div>
                          </div>
                          <div className={styles.cnt}>
                            <Fab color="secondary" className={styles.btn} aria-label="add" onClick={() => addToBasket(itemRelated)}>
                              <AddIcon />
                            </Fab>
                            <span>
                              {inBasketRelated.length > 0 ? inBasketRelated[0].count : 0}
                            </span>
                            <Fab color="secondary" className={styles.btn} aria-label="add" disabled={inBasketRelated.length === 0} onClick={() => removeFromBasket(itemRelated)}>
                              <RemoveIcon />
                            </Fab>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    )
  }

  Cart = () => {
    const { addToBasket, removeFromBasket, BasketList, AppSetting, setShowContactContainer, setShowContact,
      setShowPaymentTypeContainer, setShowPaymentType, idOrderType, backToProductList, setShowPager,
      setShowPagerContainer, InvoiceInsert, setShowCardStepContainer, setShowCardStep } = this.props;
    const { language } = this.props;

    const switchTranslate = (fa, en) => {
      if (language === "fa")
        return fa;
      else
        return en;
    }

    return (
      <div className={styles.cart}>
        <div className={styles.table}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{this.translate(WordsEnum.Image)}</TableCell>
                <TableCell align="center">{this.translate(WordsEnum.Name)}</TableCell>
                <TableCell align="center">{this.translate(WordsEnum.Calories)}</TableCell>
                <TableCell align="center">{this.translate(WordsEnum.Price)}</TableCell>
                <TableCell align="center">{this.translate(WordsEnum.Sum)}</TableCell>
                <TableCell align="center">{this.translate(WordsEnum.Count)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                [...BasketList.filter(f => f.count > 0).sort(function (a, b) {
                  return switchTranslate(a.ProductName, a.LatinName)
                    .localeCompare(switchTranslate(b.ProductName, b.LatinName));
                })].map((item, index) => {
                  const { count, ...product } = item
                  return (
                    <TableRow key={index}>
                      <StyledTableCell align="center" component="th" scope="row" className={styles.img}>
                        <img src={item.IDPicture ? MEDIAFILE(item.IDPicture) : food} alt=""></img>
                      </StyledTableCell>
                      <StyledTableCell align="center">{switchTranslate(item.ProductName, item.LatinName)}</StyledTableCell>
                      <StyledNumberTableCell align="center">{item.Calories || 0}</StyledNumberTableCell>
                      <StyledNumberTableCell align="center">{numberWithCommas(item.UnitPrice)}</StyledNumberTableCell>
                      <StyledNumberTableCell align="center">{numberWithCommas(item.UnitPrice * item.count)}</StyledNumberTableCell>
                      <StyledTableCell align="center" className={styles.action}>
                        <Fab size="small" color="secondary" className={styles.btn} aria-label="add" onClick={() => addToBasket(product)}>
                          <AddIcon />
                        </Fab>
                        <span>
                          {item.count}
                        </span>
                        <Fab size="small" color="secondary" className={styles.btn} aria-label="remove" disabled={item.length === 0} onClick={() => removeFromBasket(product)}>
                          <RemoveIcon />
                        </Fab>
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>

        <div className={styles.sum}>
          <div>
            <span>  {this.translate(WordsEnum.TotalAmount)} : </span>
            <span>
              <span className={styles.price}>{numberWithCommas(BasketList.map(m => m.UnitPrice * m.count).reduce((a, b) => a + b, 0))}</span>
              <span>{AppSetting.CurrencySymbol}</span>
            </span>
          </div>
          <div>
            <span> {this.translate(WordsEnum.TaxesAndDuties)} : </span>
            <span>
              <span className={styles.price}>{numberWithCommas(BasketList.map(m => (m.TaxPercent + m.TollPercent) * m.PriceAfterDiscount * m.count / 100).reduce((a, b) => a + b, 0))}</span>
              <span>{AppSetting.CurrencySymbol}</span>
            </span>
          </div>
          <div>
            <span> {this.translate(WordsEnum.Discount)} : </span>
            <span>
              <span className={styles.price}>{numberWithCommas(BasketList.map(m => m.DiscountPrice * m.count).reduce((a, b) => a + b, 0))}</span>
              <span>{AppSetting.CurrencySymbol}</span>
            </span>
          </div>
          <div>
            <span>  {this.translate(WordsEnum.Payable)} : </span>
            <span>
              <span className={styles.price}>{numberWithCommas(BasketList.map(m => ((m.TaxPercent + m.TollPercent) * m.PriceAfterDiscount * m.count / 100) + m.PriceAfterDiscount * m.count).reduce((a, b) => a + b, 0))}</span>
              <span>{AppSetting.CurrencySymbol}</span>
            </span>
          </div>
        </div>
        <div className={styles.action}>
          <Button variant="contained" color="primary" type="submit" className={styles.button} onClick={() => {
            if (AppSetting.EnablePager && idOrderType === 1) {
              setShowPagerContainer(true);
              backToProductList();
              setShowPager(true)
            }
            else if (AppSetting.EnableTel) {
              setShowContactContainer(true);
              backToProductList();
              setShowContact(true)
            }
            else if (AppSetting.EnableCashPay && AppSetting.EnableCardPay) {
              setShowPaymentTypeContainer(true);
              backToProductList();
              setShowPaymentType({ status: true, pager: 0 })
            }
            else {
              if (AppSetting.EnableCashPay)
                InvoiceInsert();
              else {
                setShowCardStepContainer(true);
                backToProductList();
                setShowCardStep({ type: 1, status: true });
              }
            }
          }}>   {this.translate(WordsEnum.ThePayment)} </Button>
          <Button variant="contained" color="primary" type="submit" className={styles.back} onClick={backToProductList}>{this.translate(WordsEnum.Back)}</Button>
        </div>
      </div>
    )
  }

  GroupSelector = () => {
    const { productGroups, getProducts, BasketList, setShowCart, setShowProductCartContainer,
      idOrderType, AppSetting } = this.props;
    const { mediaScreen } = this.state
    let rows = mediaScreen === "xs" ? 4 : mediaScreen === "sm" ? 4 : mediaScreen === "md" ? 5 : mediaScreen === "lg" ? 6 : 7

    const settings = {
      dots: false,
      speed: AppSetting.SliderDelay * 1000,
      slidesToShow: productGroups.length < rows ? productGroups.length : rows,
      slidesToScroll: 1,
      autoplay: false,
      arrows: false
    }

    return (
      <div className={styles.headerGroup} >
        <div className={styles.cart} onClick={() => {
          setShowCart(true);
          setShowProductCartContainer(true)
        }}>
          <div className={styles.img}>
            <img src={cart} alt=""></img>
            <span>{BasketList.map(m => m.count).reduce((a, b) => a + b, 0)}</span>
          </div>
          <span>
            <span>{numberWithCommas(BasketList.map(m => m.PriceAfterDiscount * m.count).reduce((a, b) => a + b, 0))}</span>
            <hr></hr>
            <span>{AppSetting.CurrencySymbol}</span>
          </span>
        </div>
        <div className={styles.SelectGroup}>
          <div className={styles.navleft}>
            {/* <Fab size="large" color="secondary" className={styles.btn} aria-label="add" onClick={() => this.groupSlider.slickNext()}> */}
            <NavigateBeforeIcon fontSize="large" onClick={() => this.groupSlider.slickNext()} />
            {/* </Fab> */}
          </div>
          <Slider ref={slider => (this.groupSlider = slider)} {...settings} className={styles.groupSlider}>
            {
              productGroups.map((item, index) => (
                <div key={index} onClick={() => getProducts({ idOrderType, idGroup: item.ID })}>
                  <div className={styles.groupSliderItem} >
                    <div>
                      <img className={styles.img} src={item.IDPicture ? MEDIAFILE(item.IDPicture) : food} alt=""></img>
                    </div>
                    <span>{item.ItemName}</span>
                  </div>
                </div>
              ))
            }
          </Slider>
          <div className={styles.navright}>
            {/* <Fab size="large" color="secondary" className={styles.btn} aria-label="add" onClick={() => this.groupSlider.slickPrev()}> */}
            <NavigateNextIcon fontSize="large" onClick={() => this.groupSlider.slickPrev()} />
            {/* </Fab> */}
          </div>
        </div>
      </div>
    )
  };

  onChangeInvoiceNumberInput = event => {
    let input = event.target.value;
    this.keyboardInvoiceNumber.setInput(input);

    this.setState({
      invoiceNumber: input
    });
  };

  onChangeInputInvoiceNumber = event => {
    let input = event.target.value;
    this.keyboardInvoiceNumber.setInput(input);

    this.setState({
      invoiceNumber: input
    });
  };

  onChangeInvoiceNumberInput = event => {
    let input = event.target.value;
    this.keyboardInvoiceNumber.setInput(input);

    this.setState({
      invoiceNumber: input
    });
  };

  onChangeInvoiceNumber = input => {
    this.setState({
      invoiceNumber: input
    });
    this.invoiceNumberInput.value = input
  };

  handleShiftInvoiceNumber = () => {
    let layoutNameInvoiceNumber = this.state.layoutNameInvoiceNumber;
    this.setState({
      layoutNameInvoiceNumber: layoutNameInvoiceNumber === "default" ? "shift" : "default"
    });
  };

  onKeyPressInvoiceNumber = button => {
    if (button === "{shift}" || button === "{lock}") this.handleShiftInvoiceNumber();
    if (button === "{enter}") {
      // const { products, selectedProduct } = this.props;
      // const item = products.filter(f => f.ID === selectedProduct)[0]
      // this.confirmExplanation(item);
    }
  };

  checkUserMobile = value => {
    const MOBILE_REGEX = /^9[0-9]{9}$/
    this.setState({
      userMobileErr: !value || !MOBILE_REGEX.test(value) ? true : undefined
    });
  }

  onChangeInputUserMobile = event => {
    let input = event.target.value;
    this.keyboardUserMobile.setInput(input);
    this.checkUserMobile(input)
    this.setState({
      userMobile: input
    });
  };

  onChangeUserMobile = input => {
    this.setState({
      userMobile: input
    });
    this.userMobileInput.value = input
    this.checkUserMobile(input)
  };

  handleShiftUserMobile = () => {
    let layoutNameUserMobile = this.state.layoutNameUserMobile;
    this.setState({
      layoutNameUserMobile: layoutNameUserMobile === "default" ? "shift" : "default"
    });
  };

  onKeyPressUserMobile = button => {
    if (button === "{shift}" || button === "{lock}") this.handleShiftUserMobile();
    if (button === "{enter}") {
      // const { products, selectedProduct } = this.props;
      // const item = products.filter(f => f.ID === selectedProduct)[0]
      // this.confirmExplanation(item);
    }
  };

  onChangeInputPager = event => {
    let input = event.target.value;
    this.keyboard.setInput(input);
  };

  onChangePager = input => {
    this.setState({
      pager: input
    });
    this.pagerInput.value = input
  };

  handleShiftPager = () => {
    let layoutNamePager = this.state.layoutNamePager;
    this.setState({
      layoutNamePager: layoutNamePager === "default" ? "shift" : "default"
    });
  };

  onKeyPressPager = button => {
    if (button === "{shift}" || button === "{lock}") this.handleShiftPager();
    if (button === "{enter}") {
      const { products, selectedProduct } = this.props;
      const item = products.filter(f => f.ID === selectedProduct)[0]
      this.confirmExplanation(item);
    }
  };

  Pager = () => {
    const { setShowPaymentType, setShowPaymentTypeContainer,
      setShowPager, AppSetting, setShowCart, setShowProductCartContainer, setShowContactContainer,
      setShowContact, InvoiceInsert, setShowCardStepContainer, setShowCardStep } = this.props

    return (
      <div className={styles.pager}>
        <div className={styles.title}> {this.translate(WordsEnum.DeskOrPagerNumber)}  </div>
        <div>
          <FormControl>
            <Input
              autoComplete="off"
              name='pager'
              type='text'
              inputRef={r => (this.pagerInput = r)}
              inputProps={{ style: { textAlign: 'center' } }}
              onChange={e => this.onChangeInputPager(e)}
            />
          </FormControl >
        </div>
        <div className={styles.numpad}>
          <KeyboardReact
            keyboardRef={r => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            className={styles.keypad}
            layout={numpad}
            display={{
              '{bksp}': '&#8592;',
              '{enter}': '< enter',
              '{shift}': 'shift',
              '{s}': 'shift',
              '{tab}': 'tab',
              '{lock}': 'caps',
              '{accept}': 'Submit',
              '{space}': ' ',
              '{//}': ' '
            }}
            theme={"hg-theme-default hg-layout-numeric numeric-theme " + styles.keypad}
            onChange={input => this.onChangePager(input)}
            onKeyPress={button => this.onKeyPressPager(button)}
          />
          <Button variant="contained" color="primary" type="submit" className={styles.button} disabled={this.state.pager === ""} onClick={() => {
            if (AppSetting.EnableTel) {
              // setShowPaymentType({ status: true, pager: this.state.pager })
              setShowContactContainer(true);
              setShowPager(false);
              setShowContact(true);
            }
            else if (AppSetting.EnableCashPay && AppSetting.EnableCardPay) {
              setShowPaymentTypeContainer(true);
              setShowPager(false);
              setShowPaymentType({ status: true, pager: this.state.pager })
            }
            else {
              if (AppSetting.EnableCashPay)
                InvoiceInsert();
              else {
                setShowCardStepContainer(true);
                setShowPager(false);
                setShowCardStep({ type: 1, status: true });
              }
            }
          }}>تایید</Button>
          <div className={styles.action}>
            <Button variant="contained" color="primary" type="submit" className={styles.back} onClick={() => {
              setShowPager(false);
              setShowCart(true);
              setShowProductCartContainer(true);
            }}>  {this.translate(WordsEnum.Back)} </Button>
          </div>
        </div>
      </div>
    )
  }

  Contact = () => {
    const { receiveUserInfo, insertByTel, getByTel, setShowContact, userInfo,
      setShowPaymentTypeContainer, setShowPaymentType, AppSetting, idOrderType,
      setShowPagerContainer, setShowPager, setShowCart, setShowProductCartContainer,
      InvoiceInsert, setShowCardStepContainer, setShowCardStep } = this.props
    return (
      <div className={styles.contact}>
        {
          (userInfo === null || userInfo === '' || userInfo.ID === -1) && <div className={styles.field}>
            <div className={styles.title}> {this.translate(WordsEnum.MobileNumber)} </div>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="userMobile"
                style={{ direction: 'ltr' }}
                inputRef={r => (this.userMobileInput = r)}
                onChange={e => this.onChangeInputUserMobile(e)}
                onFocus={e => {
                  receiveUserInfo(null)
                  this.onChangeFieldFooter("", false)
                }}
                autoComplete="off"
                placeholder="9000000000"
                className={styles.numberFont}
                startAdornment={<InputAdornment position="start" className={styles.numberFont}>0</InputAdornment>}
                labelWidth={0}
              />
            </FormControl>
          </div>
        }
        {userInfo === null || userInfo === '' ?
          <>

          </> : userInfo.ID === -1 ?
            <>
              <div className={styles.field}>
                <div className={styles.title}> {this.translate(WordsEnum.Name)}</div>
                <FormControl fullWidth variant="outlined" className={this.state.fieldFooter === "userName" ? styles.active : null}>
                  {/* <InputLabel htmlFor="userName">نام</InputLabel> */}
                  <OutlinedInput
                    id="userName"
                    autoComplete="off"
                    value={this.state.userName}
                    onChange={e => this.onChangeInputFooter("userName", e)}
                    onFocus={e => this.onChangeFieldFooter("userName", true)}

                    labelWidth={0}
                  />
                </FormControl>
              </div>
              <div className={styles.field}>
                <div className={styles.title}> {this.translate(WordsEnum.Family)}</div>
                <FormControl fullWidth variant="outlined" className={this.state.fieldFooter === "userFamily" ? styles.active : null}>
                  <OutlinedInput
                    id="userFamily"
                    autoComplete="off"
                    value={this.state.userFamily}
                    onChange={e => this.onChangeInputFooter("userFamily", e)}
                    onFocus={e => this.onChangeFieldFooter("userFamily", true)}
                    labelWidth={0}
                  />
                </FormControl>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>{this.translate(WordsEnum.Email)}</div>
                <FormControl fullWidth variant="outlined" className={this.state.fieldFooter === "userEmail" ? styles.active : null}>
                  <OutlinedInput
                    id="userEmail"
                    autoComplete="off"
                    value={this.state.userEmail}
                    onChange={e => this.onChangeInputFooter("userEmail", e)}
                    onFocus={e => this.onChangeFieldFooter("userEmail", true)}
                    labelWidth={0}
                  />
                </FormControl>
              </div>
            </> : <>
              <div className={styles.info}>
                <span>{this.translate(WordsEnum.Name)} :</span>
                <span>{userInfo.FirstName}</span>
              </div>
              <div className={styles.info}>
                <span>{this.translate(WordsEnum.Family)} :</span>
                <span>{userInfo.LastName}</span>
              </div>
              <div className={styles.info}>
                <span> {this.translate(WordsEnum.Email)} :</span>
                <span>{userInfo.Email}</span>
              </div>
            </>
        }

        <div className={styles.numpad}>
          {userInfo === null ?
            <KeyboardReact
              keyboardRef={r => (this.keyboardUserMobile = r)}
              layoutName={this.state.layoutNameUserMobile}
              className={styles.keypad}
              layout={numpad}
              display={{
                '{bksp}': '&#8592;',
                '{enter}': '< enter',
                '{shift}': 'shift',
                '{s}': 'shift',
                '{tab}': 'tab',
                '{lock}': 'caps',
                '{accept}': 'Submit',
                '{space}': ' ',
                '{//}': ' '
              }}
              theme={"hg-theme-default hg-layout-numeric numeric-theme " + styles.keypad}
              onChange={input => this.onChangeUserMobile(input)}
              onKeyPress={button => this.onKeyPressUserMobile(button)}
            /> : null}
          {/* <div> */}
          <Button variant="contained" color="primary" type="submit" className={styles.button}
            disabled={this.state.userMobile === "" || this.state.userMobileErr} onClick={() => {
              if (userInfo === null && userInfo === '')
                getByTel({ tel: this.state.userMobile });
              else if (userInfo !== null && userInfo !== '' && userInfo.ID === -1) {
                insertByTel({
                  FirstName: this.state.userName,
                  LastName: this.state.userFamily,
                  Email: this.state.userEmail,
                  Tel: this.state.userMobile
                })
                this.closeKeyPadFooter();
              }
              else if (AppSetting.EnableCashPay && AppSetting.EnableCardPay) {
                setShowPaymentTypeContainer(true)
                setShowContact(false)
                setShowPaymentType({ status: true, pager: null })
              }
              else {

                if (AppSetting.EnableCashPay)
                  InvoiceInsert();
                else {
                  setShowCardStepContainer(true);
                  setShowContact(false);
                  setShowCardStep({ type: 1, status: true });
                }
              }
            }}>تایید</Button>

          <div className={styles.action}>
            <Button variant="contained" color="primary" type="submit" className={styles.back} onClick={() => {
              this.closeKeyPadFooter();
              if (AppSetting.EnablePager && idOrderType === 1) {
                setShowContact(false);
                setShowPagerContainer(true);
                setShowPager(true);
              }
              else {
                setShowContact(false);
                setShowCart(true);
                setShowProductCartContainer(true);
              }
            }}>{this.translate(WordsEnum.Back)}</Button>
          </div>
        </div>
      </div>
    )
  }

  PaymentType = () => {
    const { setShowCardStep, setShowCardStepContainer, setShowPaymentType, AppSetting, BasketList, InvoiceInsert,
      idOrderType, setShowContactContainer, setShowContact,
      setShowPagerContainer, setShowPager, setShowCart, setShowProductCartContainer } = this.props

    return <div className={styles.paymentType}>
      <div className={styles.price}>
        <span> {this.translate(WordsEnum.Payable)} : </span>
        <span>
          <span className={styles.unit}>{AppSetting.CurrencySymbol}</span>
          <span className={styles.amount}>{numberWithCommas(BasketList.map(m => ((m.TaxPercent + m.TollPercent) * m.PriceAfterDiscount * m.count / 100) + m.PriceAfterDiscount * m.count).reduce((a, b) => a + b, 0))}</span>
        </span>
      </div>
      <div className={styles.selectType}>
        <div className={styles.title}> {this.translate(WordsEnum.PaymentMethod)}</div>
        <div className={styles.type}>
          <div className={styles.card} onClick={() => {
            setShowCardStepContainer(true);
            setShowPaymentType(false);
            setShowCardStep({ type: 1, status: true });
          }} >
            <img className={styles.img} src={card} alt="" />
            <h3> {this.translate(WordsEnum.Terminals)}</h3>
          </div>
          <div className={styles.cash} onClick={() => { InvoiceInsert(); }}>
            <img className={styles.img} src={cash} alt="" />
            <h3>{this.translate(WordsEnum.Cash)}</h3>
          </div>
        </div>
      </div>
      <div className={styles.action}>
        <Button variant="contained" color="primary" type="submit" className={styles.back} onClick={() => {

          if (AppSetting.EnableTel) {
            // setShowPaymentType({ status: true, pager: this.state.pager })
            setShowContactContainer(true);
            setShowPaymentType(false)
            setShowContact(true);
          }
          else if (AppSetting.EnablePager && idOrderType === 1) {
            setShowPaymentType(false);
            setShowPagerContainer(true);
            setShowPager(true);
          }
          else {
            setShowPaymentType(false);
            setShowCart(true);
            setShowProductCartContainer(true);
          }

        }}>{this.translate(WordsEnum.Back)}</Button>
      </div>
    </div>
  }

  CardStep = () => {
    const { setShowCardStep, setShowPaymentTypeContainer, setShowPaymentType, AppSetting, idOrderType,
      setShowContactContainer, setShowContact, setShowPager, setShowCart,
      setShowProductCartContainer } = this.props

    return <div className={styles.cardStep}>
      <img src={cardAnimation} alt="" />
      <div className={styles.action}>
        <Button variant="contained" color="primary" type="submit" className={styles.back} onClick={() => {

          if (AppSetting.EnableCashPay && AppSetting.EnableCardPay) {
            setShowPaymentTypeContainer(true);
            setShowCardStep({ type: 1, status: false });
            setShowPaymentType({ status: true, pager: null });
          }
          else if (AppSetting.EnableTel) {
            // setShowPaymentType({ status: true, pager: this.state.pager })
            setShowContactContainer(true);
            setShowCardStep({ type: 1, status: false });
            setShowContact(true);
          }
          else if (AppSetting.EnablePager && idOrderType === 1) {
            setShowCardStep({ type: 1, status: false });
            setShowPagerContainer(true);
            setShowPager(true);
          }
          else {
            setShowCardStep({ type: 1, status: false });
            setShowCart(true);
            setShowProductCartContainer(true);
          }
        }}>{this.translate(WordsEnum.Back)}</Button>
      </div>
    </div>
  }

  Rating = () => {
    const { changeRating, commentSamples, getByIdInvoice, insertRating, resultRating,
      getByInvoiceNumber, getByOrderNo, ratingProducts, Reset } = this.props

    const commentItem = ratingProducts != null ? ratingProducts.filter(f => f.IDProduct === null)[0] : null;

    return (
      <div className={styles.rating}>
        {
          ratingProducts == null && resultRating == null &&
          <div className={styles.field}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="position" value={this.state.ratingInvoiceType} row>
                <FormControlLabel
                  value={3}
                  control={<Radio color="primary" />}
                  label={this.translate(WordsEnum.ReceiptNumber)}
                  onClick={event => {
                    this.setState({
                      ratingInvoiceType: 3
                    });
                  }}
                />
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label={this.translate(WordsEnum.ReceiptNumber)}
                  onClick={event => {
                    this.setState({
                      ratingInvoiceType: 1
                    });
                  }}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label={this.translate(WordsEnum.InvoiceNumber)}
                  onClick={event => {
                    this.setState({
                      ratingInvoiceType: 2
                    });
                  }}
                />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth variant="outlined"   >
              <OutlinedInput
                id="invoiceNumber"
                autoComplete="off"
                style={{ direction: 'ltr' }}
                inputRef={r => (this.invoiceNumberInput = r)}
                onChange={e => this.onChangeInvoiceNumberInput(e)}
                labelWidth={0}
              />
            </FormControl>
          </div>
        }
        <div className={styles.numpad}>
          {ratingProducts == null && resultRating == null ? <KeyboardReact
            keyboardRef={r => (this.keyboardInvoiceNumber = r)}
            layoutName={this.state.layoutNameInvoiceNumber}
            className={styles.keypad}
            layout={numpad}
            display={{
              '{bksp}': '&#8592;',
              '{enter}': '< enter',
              '{shift}': 'shift',
              '{s}': 'shift',
              '{tab}': 'tab',
              '{lock}': 'caps',
              '{accept}': 'Submit',
              '{space}': ' ',
              '{//}': ' '
            }}
            theme={"hg-theme-default hg-layout-numeric numeric-theme " + styles.keypad}
            onChange={input => this.onChangeInvoiceNumber(input)}
            onKeyPress={button => this.onKeyPressInvoiceNumber(button)}
          /> :
            resultRating == null ?
              <>
                <div className={styles.ratingAll}>
                  <h4> {this.translate(WordsEnum.RateYourOrder)}</h4>
                  <Rating
                    name="simple-controlled"
                    value={commentItem.Rate}
                    max={5}
                    size="large"
                    onChange={(value) => {
                      ratingProducts.forEach(element => {
                        changeRating({ ...element, Rate: value });
                      });
                    }}
                  />
                </div>
                <div className={styles.ratingList}>
                  {
                    ratingProducts.filter(f => f.IDProduct !== null)
                      .sort(function (a, b) { return this.switchTranslate(a.ProductName, a.LatinName).localeCompare(this.switchTranslate(b.ProductName, b.LatinName)); })
                      .map((item, index) => (
                        <div key={index} className={styles.item}>
                          <div className={styles.img}>
                            <img src={item.IDPicture ? MEDIAFILE(item.IDPicture) : food} alt=""></img>
                          </div>
                          <div className={styles.ratingBox}>
                            <h4>{this.switchTranslate(item.ProductName, item.LatinName)}</h4>
                            <Rating
                              name="simple-controlled"
                              value={item.Rate}
                              max={5}
                              size="large"
                              onChange={(value) => {
                                changeRating({ ...item, Rate: value });
                              }}
                            />
                          </div>
                        </div>
                      ))
                  }
                </div>
                <div className={styles.commentSampleList}>
                  <div className={styles.badComment}>
                    <span> {this.translate(WordsEnum.Dissatisfaction)}</span>
                    {
                      commentSamples.filter(f => !f.IsPositive).sort(function (a, b) { return a.ItemName.localeCompare(b.ItemName); })
                        .map((item, index) => {
                          return (
                            <div className={styles.comment} key={index}>
                              <ToggleButton
                                value={item.ID}
                                className={styles.btn + (commentItem.CommentSamples.includes(item.ID) ? " " + styles.selected : "")}
                                selected={commentItem.CommentSamples.includes(item.ID)}
                                onChange={(value) => {
                                  changeRating({
                                    ...commentItem,
                                    CommentSamples: commentItem.CommentSamples.includes(item.ID) ?
                                      commentItem.CommentSamples.filter(f => f !== item.ID) :
                                      [...commentItem.CommentSamples, item.ID]
                                  });
                                }}>
                                {item.ItemName}
                              </ToggleButton>
                            </div>
                          )
                        })
                    }
                  </div>
                  <div className={styles.godComment}>
                    <span> {this.translate(WordsEnum.Satisfaction)}</span>
                    {
                      commentSamples.filter(f => f.IsPositive).sort(function (a, b) { return a.ItemName.localeCompare(b.ItemName); })
                        .map((item, index) => {
                          return (
                            <div className={styles.comment} key={index}>
                              <ToggleButton
                                value={item.ID}
                                className={styles.btn + (commentItem.CommentSamples.includes(item.ID) ? " " + styles.selected : "")}
                                selected={commentItem.CommentSamples.includes(item.ID)}
                                onChange={(value) => {
                                  changeRating({
                                    ...commentItem,
                                    CommentSamples: commentItem.CommentSamples.includes(item.ID) ?
                                      commentItem.CommentSamples.filter(f => f !== item.ID) :
                                      [...commentItem.CommentSamples, item.ID]
                                  });
                                }}>
                                {item.ItemName}
                              </ToggleButton>
                            </div>
                          )
                        })
                    }
                  </div>
                </div>
                <div className={styles.commentAll}>
                  <h4> {this.translate(WordsEnum.Comments)} : </h4>
                  <FormControl fullWidth variant="outlined"   >
                    <TextField
                      autoComplete="off"
                      multiline
                      rows="4"
                      value={this.state.comment}
                      onChange={e => this.onChangeInputFooter("comment", e)}
                      onFocus={e => this.onChangeFieldFooter("comment", true)}
                    />
                  </FormControl>
                  {
                    this.state.showKeyPadFooter ? <div>
                      <Fab size="small" color="primary" aria-label="add" onClick={() => this.closeFooter()}>
                        <DoneIcon />
                      </Fab>
                    </div> : null
                  }
                </div>

              </> :
              resultRating.ResponseMessage
          }
          {
            resultRating == null && <Button variant="contained" color="primary" type="submit" className={styles.button} disabled={this.state.invoiceNumber === ""} onClick={() => {
              if (ratingProducts == null) {
                if (this.state.ratingInvoiceType === 1)
                  getByIdInvoice({ value: this.state.invoiceNumber });
                else if (this.state.ratingInvoiceType === 2)
                  getByInvoiceNumber({ value: this.state.invoiceNumber });
                else if (this.state.ratingInvoiceType === 3)
                  getByOrderNo({ value: this.state.invoiceNumber });
              }
              else if (resultRating == null && ratingProducts != null) {
                insertRating({ comment: this.state.comment });
              }
            }}>{
                ratingProducts == null ? this.translate(WordsEnum.Check) : this.translate(WordsEnum.PostComment)
              }</Button>
          }
          <div className={styles.action}>
            <Button variant="contained" color="primary" type="submit" className={styles.back} onClick={() => { Reset(); }}>{this.translate(WordsEnum.back)}</Button>
          </div>
        </div>
      </div >
    )
  }

  Factor = () => {
    const { factorData, AppSetting, Reset } = this.props

    class ComponentToPrint extends React.Component {
      render() {
        return (
          <div className={styles.show}>
            {ReactHtmlParser(factorData)}
          </div>
        );
      }
    }

    if (factorData != null && factorData !== '' && this.componentRef != null && this.factorPrintRef != null) {
      let hp = this.factorPrintRef
      setTimeout(function () {
        hp.handlePrint();

        setTimeout(function () {
          Reset();
        }, 1000);
      }, 1000);
    }

    return <div className={styles.factor}>
      {
        factorData === '' && <div className={styles.loader}></div>
      }

      <ReactToPrint
        trigger={() => (<div></div>)}
        content={() => this.componentRef}
        ref={e => {
          this.factorPrintRef = e;
        }}
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={el => { this.componentRef = el }} />
      </div>

      {
        <div className={styles.goodbye}>
          {AppSetting.GoodBye || this.translate(WordsEnum.ThanksForYourShopping)}
        </div>
      }
    </div >;
  }

  changePage = (page) => () => {
    this.setState({ page })
  }

  componentWillReceiveProps(nextProps) {
    this.previousView = this.props.location;
  }

  confirmExplanation = item => {
    this.props.changeToBasket({ ...item, Comment: this.state.produstExplanation })
    this.setState({
      showKeyPadFooter: false
    });
  }

  closeFooter = () => {
    this.setState({
      showKeyPadFooter: false
    });
  }

  selectExplanation = (item, comment) => {
    if (this.state.showKeyPadFooter) {
      if (this.state.produstExplanation.split(',').filter(f => f === comment).length === 0)
        this.setState({
          produstExplanation: this.state.produstExplanation + (this.state.produstExplanation === "" ? "" : ",") + comment
        });
    }
    else {
      if (this.state.produstExplanation.split(',').filter(f => f === comment).length === 0) {
        this.props.changeToBasket({ ...item, Comment: this.state.produstExplanation + (this.state.produstExplanation === "" ? "" : ",") + comment })
        this.setState({
          produstExplanation: this.state.produstExplanation + (this.state.produstExplanation === "" ? "" : ",") + comment
        });
      }
    }
  }

  onChangeFooter = input => {
    if (this.state.fieldFooter === "produstExplanation")
      this.setState({
        produstExplanation: input
      });
    else if (this.state.fieldFooter === "userName")
      this.setState({
        userName: input
      });
    else if (this.state.fieldFooter === "userFamily")
      this.setState({
        userFamily: input
      });
    else if (this.state.fieldFooter === "userEmail")
      this.setState({
        userEmail: input
      });
    else if (this.state.fieldFooter === "comment")
      this.setState({
        comment: input
      });
  };

  onKeyPressFooter = button => {
    if (button === "{shift}" || button === "{lock}") this.handleShiftFooter();
  };

  handleShiftFooter = () => {
    let layoutNameFooter = this.state.layoutNameFooter;
    this.setState({
      layoutNameFooter: layoutNameFooter === "default" ? "shift" : "default"
    });
  };

  onChangeInputFooter = (field, event, item) => {
    let input = event.target.value;
    this.keyboardFooter.setInput(input);
    if (field === "produstExplanation")
      this.setState({
        fieldFooter: field,
        produstExplanation: input
      });
    else if (field === "userName")
      this.setState({
        fieldFooter: field,
        userName: input
      });
    else if (field === "userFamily")
      this.setState({
        fieldFooter: field,
        userFamily: input
      });
    else if (field === "userEmail")
      this.setState({
        fieldFooter: field,
        userEmail: input
      });
    else if (field === "comment")
      this.setState({
        fieldFooter: field,
        comment: input
      });
  };

  closeKeyPadFooter = () => {
    this.setState({
      showKeyPadFooter: false
    });
  }

  onChangeFieldFooter = (field, inF) => {
    this.setState({
      fieldFooter: field,
      showKeyPadFooter: inF
    });

    if (this.keyboardFooter != null) {
      if (field === "produstExplanation")
        this.keyboardFooter.setInput(this.state.produstExplanation);
      else if (field === "userName")
        this.keyboardFooter.setInput(this.state.userName);
      else if (field === "userFamily")
        this.keyboardFooter.setInput(this.state.userFamily);
      else if (field === "userEmail")
        this.keyboardFooter.setInput(this.state.userEmail);
      else if (field === "comment")
        this.keyboardFooter.setInput(this.state.comment);
    }
  }

  handleChangeLanguageFooter = (event, language) => {
    this.setState({
      languageFooter: language
    });
  };

  onEnterKeyPadFooter = () => {
    // const { produstExplanation } = this.state
    // if (this.state.fieldFooter === "produstExplanation")
    //   this.keyboardFooter.setInput(produstExplanation);
  }

  tick = () => {
    const { lastRequest, Reset } = this.props;

    setTimeout(() => {
      if (lastRequest !== null && new Date().getTime() - lastRequest > 60000) {
       Reset();
       this.tick();
      }
      else if (this.timer !== false)
        this.tick();
    }, 1000);
    this.timer = true;
  }

  render() {
    const {
      setShowWellcomeSlider,
      setShowSelectType,
      setShowIdentity,
      setShowProducts,
      setShowProductCartContainer,
      setShowPagerContainer,
      setShowContactContainer,
      setShowRatingContainer,
      setShowPaymentTypeContainer,
      setShowCardStepContainer,
      setShowProductsGroupsContainer,
      setShowGroups,
      setShowRating,
      setShowTouchTypeContainer,
      setShowTizer,
      setShowTopSlider,
      selectType,
      designStates,
      AppSetting,
      modelConfig,
      sliders,
      reseting,
      ResetTimer,
      Reset,
      changeLanguage,
      signout } = this.props;

    const { mediaScreen } = this.state

    const ProductSelector = this.ProductSelector;
    const Factor = this.Factor;

    const settings = {
      dots: false,
      speed: AppSetting.SliderDelay * 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false
    }
    if (this.timer === undefined) {
      this.tick();
      document.addEventListener("click", function () {
        ResetTimer();
      });
    }

    return (
      <div className={styles.container}>
        <div className={styles.topSlider}>
          <CSSTransition
            in={designStates.showTizer && AppSetting.ClipAddress}
            timeout={speedSlide}
            classNames={fadeTransition}
            onExited={() => {
              setShowTopSlider(true);
            }}
            unmountOnExit >
            {
              <div className={styles.tizer}>
                <ReactPlayer className={styles.video}
                  width="100%"
                  url={"http://"+modelConfig.configWebservice + "/zhaniyaapi/api/MediaFile/Getvideo?videoname=" + AppSetting.ClipAddress}
                  playing muted loop />
                {!designStates.showTouch &&
                  <div className={styles.cancelButton}>
                    <Button onClick={Reset}>
                      {this.translate(WordsEnum.Cancel)}
                      <ArrowBackIcon></ArrowBackIcon>
                    </Button>
                  </div>
                }
              </div>
            }
          </CSSTransition>

          <CSSTransition
            in={designStates.showTopSlider || (designStates.showTizer && !AppSetting.ClipAddress)}
            timeout={speedSlide}
            classNames={fadeTransition}
            onExited={() => {

            }}
            unmountOnExit >
            <div className={styles.slider}>
              <Slider {...settings} className={styles.slider}>
                {
                  sliders.map((item, index) => {
                    return (
                      <div key={index} >
                        <img className={styles.img} src={MEDIAFILE(item)} alt=""></img>
                      </div>
                    )
                  })
                }
              </Slider>
              {
                (designStates.showTopSlider || !designStates.showTouch) ?

                  <>
                    {
                      designStates.showTopSlider ? <div className={styles.discountSwitch}>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              onChange={(event) => {
                                this.setState({
                                  discountProduct: event.target.checked
                                });
                              }} />
                          }
                          label={this.translate(WordsEnum.Discounted)}
                        />
                      </div> : null
                    }

                    <div className={styles.cancelButton}>
                      <Button onClick={Reset}>
                        {this.translate(WordsEnum.Cancel)}
                        <ArrowBackIcon></ArrowBackIcon>
                      </Button>
                    </div>
                  </>
                  : null
              }

            </div>
          </CSSTransition>
        </div>

        <div className={styles.content}>
          <CSSTransition
            in={designStates.showWellcomeSlider}
            timeout={speedSlide}
            classNames={slideToUpTransition}
            onExited={() => {
              setShowGroups(true);
              setShowProducts(true);
              setShowProductsGroupsContainer(true);
              if (AppSetting.ClipAddress)
                setShowTizer(false);
              else
                setShowTopSlider(true);
            }}
            unmountOnExit >
            {this.DailyProductSlider}
          </CSSTransition>

          <div className={styles.cartproduct} style={{ display: designStates.showProductCartContainer ? "flex" : "none" }}>
            <CSSTransition
              in={designStates.showProduct}
              timeout={speedSlide}
              classNames={{ ...slideToRightTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowProductCartContainer(false)
                }
              }}
              unmountOnExit>
              {this.Product}
            </CSSTransition>

            <CSSTransition
              in={designStates.showCart}
              timeout={speedSlide}
              classNames={{ ...slideToLeftTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowProductCartContainer(false)
                }
              }}
              unmountOnExit>
              {this.Cart}
            </CSSTransition>
          </div>

          <div className={styles.pagercontainer} style={{ display: designStates.showPagerContainer ? "flex" : "none" }}>
            <CSSTransition
              in={designStates.showPager}
              timeout={speedSlide}
              classNames={{ ...slideToRightTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowPagerContainer(false)
                }
              }}
              unmountOnExit>
              {this.Pager}
            </CSSTransition>
          </div>

          <div className={styles.contactcontainer} style={{ display: designStates.showContactContainer ? "flex" : "none" }}>
            <CSSTransition
              in={designStates.showContact}
              timeout={speedSlide}
              classNames={{ ...slideToRightTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowContactContainer(false)
                }
              }}
              unmountOnExit>
              {this.Contact}
            </CSSTransition>
          </div>

          <div className={styles.ratingcontainer} style={{ display: designStates.showRatingContainer ? "flex" : "none" }}>
            <CSSTransition
              in={designStates.showRating}
              timeout={speedSlide}
              classNames={{ ...slideToRightTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowRatingContainer(false)
                }
              }}
              unmountOnExit>
              {this.Rating}
            </CSSTransition>
          </div>

          <div className={styles.paymentTypecontainer} style={{ display: designStates.showPaymentTypeContainer ? "flex" : "none" }}>
            <CSSTransition
              in={designStates.showPaymentType}
              timeout={speedSlide}
              classNames={{ ...slideToRightTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowPaymentTypeContainer(false)
                }
              }}
              unmountOnExit>
              {this.PaymentType}
            </CSSTransition>
          </div>

          <div className={styles.cardStepcontainer} style={{ display: designStates.showCardStepContainer ? "flex" : "none" }}>
            <CSSTransition
              in={designStates.showCardStep}
              timeout={speedSlide}
              classNames={{ ...slideToRightTransition }}
              onExited={() => {
                if (!reseting) {
                  setShowCardStepContainer(false)
                }
              }}
              unmountOnExit>
              {this.CardStep}
            </CSSTransition>
          </div>

          <div className={styles.factorcontainer} style={{ display: designStates.showFactorContainer ? "flex" : "none" }}>
            {
              designStates.showFactor &&
              <div className={styles.factorContext}>
                <Factor></Factor>
              </div>
            }
          </div>

          <div className={styles.inner}>
            <div className={styles.touchTypeContainer} style={{ display: designStates.showTouchTypeContainer ? "flex" : "none" }}>
              <CSSTransition
                in={designStates.showTouch}
                timeout={speedSlide}
                classNames={slideToUpTransition}
                onExited={() => {
                  if (designStates.showRatingContainer)
                    setShowRating(true);
                  else {
                    if (AppSetting.EnableSalon && AppSetting.EnableTakeAway)
                      setShowSelectType(true);
                    else {
                      if (AppSetting.EnableSalon)
                        selectType({ idOrderType: 1 });
                      else
                        selectType({ idOrderType: 2 });
                      if (!reseting) {
                        setShowTouchTypeContainer(false);
                        setShowIdentity(false);
                        setShowWellcomeSlider(false);
                      }
                    }
                  }
                }}
                unmountOnExit >
                {this.RenderWellcome}
              </CSSTransition>

              <CSSTransition
                in={designStates.showSelectType}
                timeout={speedSlide}
                classNames={{
                  enter: slideToRightTransition.enter,
                  enterActive: slideToRightTransition.enterActive,
                  exit: slideToLeftTransition.exit,
                  exitActive: slideToLeftTransition.exitActive
                }}
                onExited={() => {
                  if (!reseting) {
                    setShowTouchTypeContainer(false);
                    setShowIdentity(false);
                    setShowWellcomeSlider(false);
                  }
                }}
                unmountOnExit >
                {this.SelectType}
              </CSSTransition>
            </div>

            <div className={styles.productsGroupsContainer} style={{ display: designStates.showProductsGroupsContainer ? "flex" : "none" }}>
              <CSSTransition
                in={designStates.showGroups}
                timeout={speedSlide}
                classNames={slideToLeftTransition}
                onExited={() => {
                  if (!reseting) {
                    setShowProductsGroupsContainer(false)
                  }
                }}
                unmountOnExit >
                {this.GroupSelector}
              </CSSTransition>

              <CSSTransition
                in={designStates.showProducts}
                timeout={speedSlide}
                classNames={slideToRightTransition}
                onExited={() => {
                  if (!reseting) {
                    setShowProductsGroupsContainer(false)
                  }
                }}
                unmountOnExit >
                <ProductSelector row={1}></ProductSelector>
              </CSSTransition>

              <CSSTransition
                in={designStates.showProducts}
                timeout={speedSlide}
                classNames={slideToRightTransition}
                onExited={() => {
                  if (!reseting) {
                    setShowProductsGroupsContainer(false)
                  }
                }}
                unmountOnExit >
                <ProductSelector row={2}></ProductSelector>
              </CSSTransition>
            </div>
          </div>

          <CSSTransition
            in={designStates.showIdentity}
            timeout={speedSlide}
            classNames={slideToDownTransition}
            unmountOnExit >
            <div className={styles.identity}>
              <div className={styles.name}>
                {AppSetting.ItemName}
              </div>
              <div className={styles.logo}>
                <img src={AppSetting.IdPicture ? MEDIAFILE(AppSetting.IdPicture) : food} alt=""></img>
              </div>
            </div>
          </CSSTransition>
        </div>

        <footer>
          <ToggleButtonGroup size={mediaScreen === "xs" ? "small" : mediaScreen === "sm" ? "small" : mediaScreen === "md" ? "large" : mediaScreen === "lg" ? "large" : "large"} exclusive className={styles.switch}
            value={this.props.language} onChange={(event, language) => {
              console.log(language)
              changeLanguage(language)
            }}>
            <ToggleButton value="fa" style={{ color: this.props.language === 'fa' ? '#70286c' : 'rgba(0, 0, 0, 0.38)' }} disabled={this.props.language === 'fa' ? true : null}> {this.translate(WordsEnum.Persian)}</ToggleButton>
            <ToggleButton value="en" style={{ color: this.props.language === 'en' ? '#70286c' : 'rgba(0, 0, 0, 0.38)' }} disabled={this.props.language === 'en' ? true : null}> {this.translate(WordsEnum.English)}</ToggleButton>
          </ToggleButtonGroup>
          <span>Zhaniya.com</span>
          <img src={zh} alt="" onClick={signout}></img>
        </footer>

        <div className={styles.keyPadContainer} >
          <CSSTransition
            in={this.state.showKeyPadFooter}
            timeout={speedSlide}
            classNames={slideToDownTransition}
            onEnter={this.onEnterKeyPadFooter}
            onExited={() => {
              this.setState({
                showKeyPadFooterContainer: !this.state.showKeyPadFooterContainer
              });
            }}
            onEntered={() => {
              if (this.state.fieldFooter === "produstExplanation")
                this.keyboardFooter.setInput(this.state.produstExplanation);
              else if (this.state.fieldFooter === "userName")
                this.keyboardFooter.setInput(this.state.userName);
              else if (this.state.fieldFooter === "userFamily")
                this.keyboardFooter.setInput(this.state.userFamily);
              else if (this.state.fieldFooter === "userEmail")
                this.keyboardFooter.setInput(this.state.userEmail);
            }}
            unmountOnExit>
            <div className={styles.keyPad}>
              <ToggleButtonGroup style={{ direction: "rtl" }} size="large"
                value={this.state.languageFooter} exclusive onChange={this.handleChangeLanguageFooter}>
                <ToggleButton
                  value="fa"
                  disabled={this.state.languageFooter === "fa"}
                >fa</ToggleButton>
                <ToggleButton
                  value="en"
                  disabled={this.state.languageFooter === "en"}
                >en</ToggleButton>
              </ToggleButtonGroup>
              <KeyboardReact
                keyboardRef={r => (this.keyboardFooter = r)}
                layoutName={this.state.layoutNameFooter}
                className={styles.keypad}
                layout={this.state.languageFooter === "fa" ? persian : english}
                onChange={input => this.onChangeFooter(input)}
                onKeyPress={button => this.onKeyPressFooter(button)}
              />
            </div>
          </CSSTransition>
        </div>
      </div >
    );
  }
}

export default connectTo(
  state => ({
    designStates: {
      showTizer: state.baseinfo.showTizer,
      showTopSlider: state.baseinfo.showTopSlider,

      showWellcomeSlider: state.baseinfo.showWellcomeSlider,
      showTouch: state.baseinfo.showTouch,
      showSelectType: state.baseinfo.showSelectType,
      showTouchTypeContainer: state.baseinfo.showTouchTypeContainer,
      showIdentity: state.baseinfo.showIdentity,
      showStartContainer: state.baseinfo.showStartContainer,

      showGroups: state.baseinfo.showGroups,
      showProducts: state.baseinfo.showProducts,
      showProductsGroupsContainer: state.baseinfo.showProductsGroupsContainer,

      showSlider: state.baseinfo.showSlider,

      showProduct: state.baseinfo.showProduct,
      showCart: state.baseinfo.showCart,
      showProductCartContainer: state.baseinfo.showProductCartContainer,

      showPager: state.baseinfo.showPager,
      showPagerContainer: state.baseinfo.showPagerContainer,

      showContact: state.baseinfo.showContact,
      showContactContainer: state.baseinfo.showContactContainer,

      showRating: state.baseinfo.showRating,
      showRatingContainer: state.baseinfo.showRatingContainer,

      showPaymentType: state.baseinfo.showPaymentType,
      showPaymentTypeContainer: state.baseinfo.showPaymentTypeContainer,

      showCardStep: state.baseinfo.showCardStep,
      showCardStepContainer: state.baseinfo.showCardStepContainer,

      showFactor: state.baseinfo.showFactor,
      showFactorContainer: state.baseinfo.showFactorContainer,
    },
    AppSetting: state.auth.AppSetting,
    BasketList: state.baseinfo.BasketList,
    products: state.baseinfo.products,
    allProducts: state.baseinfo.allProducts,
    dailyProducts: state.baseinfo.dailyProducts,
    productGroups: state.baseinfo.productGroups,
    sliderImages: state.baseinfo.sliderImages,

    modelConfig: state.auth.modelConfig,
    idOrderType: state.baseinfo.idOrderType,

    factorData: state.baseinfo.factorData,
    factorPrint: state.baseinfo.factorPrint,
    selectedProduct: state.baseinfo.selectedProduct,
    selectedProductCount: state.baseinfo.selectedProductCount,
    sliders: state.baseinfo.sliders,
    lastRequest: state.baseinfo.lastRequest,
    reseting: state.baseinfo.reseting,
    userInfo: state.baseinfo.userInfo,
    ratingProducts: state.baseinfo.ratingProducts,
    commentSamples: state.baseinfo.commentSamples,
    language: state.generic.language,
    resultRating: state.baseinfo.resultRating,
  }),
  {
    to, loadDialog, openLoading, closeLoading, toggleSnackbar,

    setShowTizer,
    setShowTopSlider,

    setShowWellcomeSlider,
    setShowTouch,
    setShowSelectType,
    setShowTouchTypeContainer,
    setShowIdentity,
    setShowStartContainer,

    setShowProductCartContainer,
    setShowProduct,
    setShowCart,

    setShowPager,
    setShowPagerContainer,

    getByTel,
    insertByTel,
    receiveUserInfo,
    setShowContact,
    setShowContactContainer,

    setShowRating,
    setShowRatingContainer,
    getByIdInvoice,
    getByInvoiceNumber,
    getByOrderNo,
    receiveInvoiceList,
    getCommentSample,
    receiveCommentSample,
    changeRating,
    insertRating,

    setShowPaymentType,
    setShowPaymentTypeContainer,

    setShowCardStep,
    setShowCardStepContainer,

    setShowFactor,
    setShowFactorContainer,

    setShowProducts,
    setShowGroups,
    setShowProductsGroupsContainer,

    GetDailyProducts,
    selectType,
    getProduct,
    getProducts,
    getProductgroup,

    changeToBasket,
    addToBasket,
    addRangeToBasket,
    removeFromBasket,
    clearFromBasket,
    resetBasket,
    getBasket,
    receiveBasket,

    addSelectedProduct,
    removeSelectedProduct,

    backToProductList,
    InvoiceInsert,
    Reset,
    ResetDone,
    sleepChecker,
    ResetTimer,
    changeLanguage,
    signout
  },
  withRouter(withStyles(styles)(Home))
)