import React, { PureComponent } from 'react'
import { connectTo } from '../_utils/generic';
import styles from './StartPage.module.scss';
import { withStyles } from '@material-ui/core/styles';
import { to } from '../_actions/navigation'
import { openLoading, closeLoading } from '../_actions/loading';
import { loadDialog } from '../_actions/dialog';
import { toggleSnackbar } from '../_actions/generic';

class StartPage extends PureComponent {

  render() {
    return (
      <div className={styles.container}>
        start
      </div>
    );
  }
}

export default connectTo(
  state => ({

  }),
  { to, loadDialog, openLoading, closeLoading, toggleSnackbar },
  withStyles(styles)(StartPage)
)