import { put, call } from 'redux-saga/effects'
import { SubmissionError } from 'redux-form'
import { to } from '../_actions/navigation'
import { receiveAuthData } from '../_actions/auth'
import { LOGIN, LOGINWITHAGENCY } from '../_constants/api'
import { post } from '../_utils/api'
import { startApp, toggleSnackbar } from '../_actions/generic'
import { callWith401Handle } from './api'

export function* submitLogin({ payload: { values, reject } }) {
  try {
    const authData = yield call(post, LOGIN, values)
    yield put(receiveAuthData(authData))
    yield put(startApp())
    yield put(to(values.from || '/agency'))
  } catch ({ status, message }) {
    yield put(toggleSnackbar("نام کاربری یا گذرواژه اشتباه است" + message))
    yield call(reject, new SubmissionError(message))
  }
}

export function* unauthorizeUser() {
  yield put(to('/config'))
}

export function* submitConfig() {
  yield put(to('/auth'))
}

export function* submitLoginAgency({ payload: { ID, IDFinancialYear, ItemName } }) {
  try {
    const retData = yield callWith401Handle(post, LOGINWITHAGENCY, { IDAgency: ID, IDFinancialYear })

    yield put(receiveAuthData({ ...retData, AppSetting: { ItemName, ...retData.AppSetting } }))  //ItemName
    yield put(to("/"))
  }
  catch ({ message }) {
    console.log('J: Error saga getListUsers: ', message)
  }
}