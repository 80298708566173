import React from 'react'

import DialogWrapper from './_components/dialogWrapper/dialogWrapper'
import LoadingWrapper from './_components/loadingWrapper/loadingWrapper'
import Snackbar from './_components/snackbar'
import ConfirmDialog from './_components/confirmDialog/confirmDialog'

import { Router } from 'react-router-dom';
import { history } from './_utils/history';

import Login from './auth/login/login';
import Agency from './auth/agency/agency';
import Config from './auth/config/config';
import Home from './home/home';
import { connectTo } from './_utils/generic'

import { MyRoute } from './_components/PrivateRoute';

class MainRoutes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <>

          <MyRoute exact={true} path={"/"} component={Home} canActivate="agency"></MyRoute>
          <MyRoute exact={true} path={"/config"} component={Config} canActivate=""></MyRoute>
          <MyRoute exact={true} path={"/auth"} component={Login} canActivate="config"></MyRoute>
          <MyRoute exact={true} path={"/agency"} component={Agency} canActivate="auth"></MyRoute>

          {/* برای نمایش دیالوگ اکشن صدا می زنیم و مقادیر ارسال می کنیم */}
          <DialogWrapper></DialogWrapper>

          {/* برای نمایش لودینگ اکشن صدا می زنیم و مقادیر ارسال می کنیم */}
          <LoadingWrapper></LoadingWrapper>

          {/* برای نمایش اسنک اکشن صدا می زنیم و مقادیر ارسال می کنیم */}
          <Snackbar></Snackbar>

          <ConfirmDialog></ConfirmDialog>
        </>
      </Router>
    )
  }
}

export default connectTo(
  state => ({
  }),
  {},
  (MainRoutes)
)
