import { ConvertBoolean } from "./generic"

export const takeIfExists = (key, type = String) => {
  const item = localStorage.getItem(key)
  if (item) {
    return type === Number
      ? Number.parseFloat(item) :
      type === Array ?
        item.split(',') :
        type === Object ?
          JSON.parse(item) :
          type === Boolean ? 
          ConvertBoolean(item) : item
  }
}


