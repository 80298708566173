import config from '../_assets/config.json';
import { takeIfExists } from '../_utils/localStorage.js';


//http://192.168.1.100/ZhaniyaApi/api/
export const BACKEND = `http://${takeIfExists('modelConfig', Object) ? takeIfExists('modelConfig', Object).configWebservice : null}/ZhaniyaApi/api/`
export const POSBACKEND = `http://${takeIfExists('modelConfig', Object) ? takeIfExists('modelConfig', Object).configPosAddress : null}`

export const SERVERFILE = `${config.REACT_APP_SERVERFILE_API_URL}`
export const NOTIFICATIONS = `${config.REACT_APP_MAIN_API_URL}notifications`

//http://192.168.1.100/ZhaniyaApi/api/user/GetAll
export const GETUSERS = `${BACKEND}user/GetAll`

//http://192.168.1.100/ZhaniyaApi/api/user/Login
export const LOGIN = `${BACKEND}user/Login`

//http://192.168.1.100/ZhaniyaApi/api/User/LoginWithAgency
export const LOGINWITHAGENCY = `${BACKEND}user/LoginWithAgency`

//http://192.168.1.100/ZhaniyaApi/api/productgroup/GetAll
export const PRODUCTGROUPS = `${BACKEND}productgroup/GetAll`

//http://192.168.1.100/ZhaniyaApi/api/MediaFile/GetFile/1
export const MEDIAFILE = IDPicture => `${BACKEND}MediaFile/GetFile/${IDPicture}`

//http://192.168.1.100/ZhaniyaApi/api/Product/GetAll
export const PRODUCTS = `${BACKEND}Product/GetAll`

//http://192.168.1.100/ZhaniyaApi/api/Product/GetSlider
export const GetSlider = `${BACKEND}Product/GetSlider`

//http://192.168.1.100/ZhaniyaApi/api/Product/GetSliderID
export const GETSLIDERID = `${BACKEND}Product/GetSliderID`

//http://192.168.1.100/ZhaniyaApi/api/Product/Get/1
export const PRODUCT = productID => `${BACKEND}Product/Get/${productID}`

//http://192.168.1.100/ZhaniyaApi/api/Person/GetByTel?value=09179338319
export const GETBYTEL = value => `${BACKEND}Person/GetByTel?value=${value}`

//http://192.168.1.100/ZhaniyaApi/api/Person/InsertByTel
export const INSERTBYTEL = `${BACKEND}Person/InsertByTel`

//ای دی میگیریه لیست اینتیجر میده
//http://192.168.1.100/ZhaniyaApi/api/Product/GetByIdInvoice?value=2172
//شناسه سفارش
export const GETBYIDINVOICE =value => `${BACKEND}Product/GetByIdInvoice?value=${value}`

//شماره فاکتور
//http://192.168.1.100/ZhaniyaApi/api/Product/GetByInvoiceNumber?value=09179338319
export const GETBYINVOICENUMBER =value => `${BACKEND}Product/GetByInvoiceNumber?value=${value}`

//شماره فیش
//http://192.168.1.100/ZhaniyaApi/api/Product/GetByOrderNo?value=101
export const GETBYORDERNO =value => `${BACKEND}Product/GetByOrderNo?value=${value}`

//http://192.168.1.100/ZhaniyaApi/api/Product/GetCommentSample
export const GETCOMMENTSAMPLE = `${BACKEND}Rating/GetCommentSample`

//http://192.168.1.100/ZhaniyaApi/api/Rating/Insert
export const INSERTRATING = `${BACKEND}Rating/Insert`

//http://192.168.1.100/ZhaniyaApi/api/Invoice/Print?ID=1064
export const PRINT = ID => `${BACKEND}Invoice/Print?ID=${ID}`

//http://192.168.1.100/ZhaniyaApi/api/wellcomeInfo
export const WELLCOMEINFO = `${BACKEND}wellcomeInfo`

//http://192.168.1.100/ZhaniyaApi/api/Product/GetTodayProducts
export const DAILYPRODUCTS = `${BACKEND}Product/GetTodayProducts`

//http://192.168.1.100/ZhaniyaApi/api/Invoice/Insert
export const INVOICEINSERT = `${BACKEND}Invoice/Insert`

//http://192.168.1.100/ZhaniyaApi/api/Invoice/GetBillPrint?id=1049
export const GETINVOICE = ID => `${BACKEND}Invoice/GetBillPrint?ID=${ID}`

//http://91.240.180.189:8024/v1/PcPosTransaction/reciveidentifier
export const RECIVEIDENTIFIER = `${POSBACKEND}/v1/PcPosTransaction/reciveidentifier`

//http://91.240.180.189:8024/v1/PcPosTransaction/Startpayment
export const STARTPAYMENT = `${POSBACKEND}/v1/PcPosTransaction/Startpayment`

export const FILEUPLOAD = folder => `${SERVERFILE}/File/Upload/${folder}`
export const FILEDOWNLOAD = filename => `${SERVERFILE}/Files/${filename}`
