import {
    createReducer
} from 'redux-act'

import * as a from '../_actions/dialog'
import { isEmpty } from '../_utils/generic'

const getDefaultState = _ => ({
    dialogContent: null,
    dialogTitle: "",
    headerColor: '#ff5050',
    openDialog: false,
    closeDialog: () => { },

    onOKMap: (location) => { },
    location: null,
    zoom: 10,
    openMap: false
})

export default _ =>
    createReducer({
        [a.loadDialog]: (state, { dialogContent, dialogTitle, openDialog, closeDialog, headerColor }) => {
            return ({
                ...state,
                dialogContent,
                dialogTitle,
                openDialog,
                closeDialog: closeDialog == null ? () => { } : closeDialog,
                headerColor: headerColor == null ? '#ff5050' : headerColor,
            })
        },
        [a.openDialog]: (state) => ({
            ...state,
            openDialog: true,
        }),
        [a.closeDialog]: (state) => ({
            ...state,
            openDialog: false,
        }),
        [a.removeDialog]: () => ({}),

        [a.openMapDialog]: (state, { onOKMap, location }) => ({
            ...state,
            openMap: true,
            location: !isEmpty(location) ? { lat: location.lat, lng: location.lng } : null,
            zoom: !isEmpty(location) ? location.zoom : 10,
            onOKMap
        }),
        [a.changeMapDialog]: (state, { location, zoom }) => ({
            ...state,
            location: location !== undefined ? location : state.location,
            zoom: zoom !== undefined ? zoom : state.zoom
        }),
        [a.closeMapDialog]: (state) => ({
            ...state,
            openMap: false
        })
    },
        getDefaultState()
    )