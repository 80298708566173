import { createAction } from 'redux-act'

export const getByIdInvoice = createAction()
export const getByInvoiceNumber = createAction()
export const getByOrderNo = createAction()
export const receiveInvoiceList = createAction()


export const getCommentSample = createAction()
export const receiveCommentSample = createAction()






