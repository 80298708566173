

export const WordsEnum = {
    Poll: {
        Fa: "نظر سنجی",
        En: "poll"
    },
    TouchToPlaceAnOrder: {
        Fa: "برای ثبت سفارش لمس کنید",
        En: "Touch to place an order"
    },
    Contents: {
        Fa: "محتویات",
        En: "Contents"
    },

    Calories: {
        Fa: "کالری",
        En: "Calories"
    },
    KitchenDescription: {
        Fa: "توضیحات آشپزخانه",
        En: "Kitchen Description"
    },
    Image: {
        Fa: "تصویر",
        En: "Image"
    },
    Name: {
        Fa: "نام",
        En: "Name"
    },
    Price: {
        Fa: "فی",
        En: "Price"
    },
    Sum: {
        Fa: "جمع",
        En: "Sum"
    },
    Count: {
        Fa: "تعداد",
        En: "Count"
    },
    TotalAmount: {
        Fa: "مبلغ کل",
        En: "Total Amount"
    },
    TaxesAndDuties: {
        Fa: "مالیات و عوارض",
        En: "Taxes and Duties"
    },
    Discount: {
        Fa: "تخفیف",
        En: "Discount"
    },
    Payable: {
        Fa: "قابل پرداخت",
        En: "Payable"
    },
    ThePayment: {
        Fa: "پرداخت",
        En: "The payment"
    },
    DeskOrPagerNumber: {
        Fa: "شماره میز یا پیجر",
        En: "Desk or pager number"
    },
    Back: {
        Fa: "برگشت",
        En: "Back"
    },
    MobileNumber: {
        Fa: "شماره موبایل",
        En: "Mobile number"
    },

    Family: {
        Fa: "نام خانوادگی",
        En: "Family"
    },
    Email: {
        Fa: "ایمیل",
        En: "Email"
    },
    PaymentMethod: {
        Fa: "روش پرداخت",
        En: "Payment Method"
    },
    Terminals: {
        Fa: "کارت خوان",
        En: "Terminals"
    },
    Cash: {
        Fa: "نقدی",
        En: "Cash"
    },
    ReceiptNumber: {
        Fa: "شماره فیش",
        En: "Receipt number"
    },
    OrderID: {
        Fa: "شناسه سفارش",
        En: "Order ID"
    },
    RateYourOrder: {
        Fa: "به سفارش خود امتیاز دهید ",
        En: "Rate your order"
    },
    Dissatisfaction: {
        Fa: "عدم رضایت",
        En: "Dissatisfaction"
    },
    Satisfaction: {
        Fa: "رضایت",
        En: "Satisfaction"
    },
    Comments: {
        Fa: "نظرات",
        En: "Comments"
    },
    Check: {
        Fa: "بررسی",
        En: "Check"
    },
    PostComment: {
        Fa: "ارسال نظر",
        En: "Post comment"
    },
    ThanksForYourShopping: {
        Fa: "از خرید شما متشکریم",
        En: "Thanks for your shopping"
    },
    Cancel: {
        Fa: "انصراف",
        En: "Cancel"
    },
    Discounted: {
        Fa: "تخفیف دار",
        En: "Discounted"
    },
    Persian: {
        Fa: "فارسی",
        En: "Persian"
    },
    English: {
        Fa: "انگلیسی",
        En: "English"
    },
};

export const Translate = (word, language) => {
    if (language === "fa")
        return word.Fa;
    else
        return word.En;
}