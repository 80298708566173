// import {
//   createMuiTheme
// } from '@material-ui/core/styles';
import lightBlue from "@material-ui/core/colors/lightBlue";
import { purple, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';



const theme = language => createMuiTheme({
  direction: language === 'fa' ? 'rtl' : 'ltr',
  typography: {
    useNextVariants: true,
    fontFamily: "\"BYekan\" , \"BKoodkBd\", \"Helvetica\", \"Arial\", sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    type: 'light',
    primary: {
      light: 'rgb(0, 203, 225)',
      main: '#34a0d9', //main
      dark: '#0d6cac', //hover
      contrastText: '#fff', //text
    },
    secondary: {
      light: '#111',
      main: '#f50057', //main
      dark: '#ff4688', //hover
      contrastText: '#fff', //text
    },
    extra: {
      light: purple[300],
      main: purple[500], //main
      dark: purple[700], //hover
      contrastText: purple[500], //text
    },
    success: {
      light: '#111',
      main: '#f50057', //main
      dark: '#ff4688', //hover
      contrastText: '#fff', //text
    },
    warning: {
      light: '#111',
      main: '#f50057', //main
      dark: '#ff4688', //hover
      contrastText: '#fff', //text
    },
    info: {
      light: '#111',
      main: '#f50057', //main
      dark: '#ff4688', //hover
      contrastText: '#fff', //text
    },
    error: red,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      isSelected: {
        backgroundColor: lightBlue["400"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
});

export default theme