import { createReducer } from 'redux-act'

import * as a from '../_actions/auth'
import { takeIfExists } from '../_utils/localStorage'

const getDefaultState = _ => ({
  ID: takeIfExists('ID'),
  FullName: takeIfExists('FullName'),
  Token: takeIfExists('Token'),
  TokenExpirationTime: takeIfExists('TokenExpirationTime', Number),
  Username: takeIfExists('Username'),
  IDAgency: takeIfExists('IDAgency'),
  AgencyList: takeIfExists('AgencyList', Object),
  AppSetting: takeIfExists('AppSetting', Object),
  modelConfig: takeIfExists('modelConfig', Object),
})

export default _ =>
  createReducer(
    {
      [a.receiveAuthData]: (state, authDate) => {
        return ({
          ...state,
          ...authDate
        })
      },
      [a.changeBackAddress]: (state, { backAddress }) => ({
        ...state,
        backAddress
      }),
      [a.submitConfig]: (state, model) => ({
        ...state,
        modelConfig: model.values
      }),
      [a.unauthorizeUser]: () => ({}),
    },
    getDefaultState()
  )
