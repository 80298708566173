import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Redirect } from 'react-router-dom';

import { submitLoginAgency } from '../../_actions/auth'
import { connectTo } from '../../_utils/generic';
import { isValid } from '../../_utils/forms'
import styles from './agency.module.scss';
import { withStyles } from '@material-ui/core/styles'
import 'react-simple-keyboard/build/css/index.css';
import { Button } from '@material-ui/core';

// const persian = {
//   'default': [
//     "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
//     "{tab} ض ص ث ق ف غ ع ه خ ح ج چ پ",
//     "{lock} ش س ی ب ل ا ت ن م ک گ {enter}",
//     "{shift} ظ ط ز ژ ر ذ د ئ و . /{shift}",
//     '.com @ {space}'
//   ],
//   'shift': [
//     "{//} 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
//     "{tab} A Z E R T Y U I O P \u00A8 \u00A3",
//     "{lock} Q S D F G H J K L M % \u00B5 {enter}",
//     "{shift} > W X C V B N ? . / \u00A7 {shift}",
//     '.com @ {space}'
//   ]
// }

// const numpad = {
//   default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
//   shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
// }

class AgencyPage extends PureComponent {
  state = {
    layoutName: "default"
  };

  render() {
    const { listFilterAgencyList, submitLoginAgency } = this.props

    return (
      <div className={styles.container}>
        {
          listFilterAgencyList && listFilterAgencyList.length > 0 ?
            null :
            <Redirect to={{ pathname: '/auth' }} />
        }

        {
          listFilterAgencyList && listFilterAgencyList.length > 0 && listFilterAgencyList.map((item, index) => (
            <Button key={index} variant="contained" color="primary" type="submit" className={styles.button} onClick={() => { submitLoginAgency({ ...item }) }}>{item.ItemName}</Button>
          ))
        }
      </div>
    )
  }
}

const formName = 'login'
export default withStyles(styles)(connectTo(
  state => ({
    enabledSubmit: isValid(state, formName),

    listFilterAgencyList: state.auth.AgencyList
  }),
  { submitLoginAgency },
  reduxForm({ form: formName, enableReinitialize: true })(AgencyPage)))
