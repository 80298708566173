import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'

import { submitLogin } from '../../_actions/auth'
import { getListUsers } from '../../_actions/baseInfo/baseinfo'
import { required } from '../../_validators/forms';
import { connectTo } from '../../_utils/generic';
import { isValid } from '../../_utils/forms'
import textLoginPassword from '../../_components/fields/textLoginPassword/textLoginPassword'
import AuthForm from '../../_components/authForm/authForm'
import styles from './login.module.scss';
import { withStyles } from '@material-ui/core/styles'
import KeyboardReact from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import autocompletefield from '../../_components/fields/autocompletefield/autocompletefield';
import { Fab } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { to } from '../../_actions/navigation';

const requiredpassword = required("گذرواژه")
// const persian = {
//   'default': [
//     "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
//     "{tab} ض ص ث ق ف غ ع ه خ ح ج چ پ",
//     "{lock} ش س ی ب ل ا ت ن م ک گ {enter}",
//     "{shift} ظ ط ز ژ ر ذ د ئ و . /{shift}",
//     '.com @ {space}'
//   ],
//   'shift': [
//     "{//} 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
//     "{tab} A Z E R T Y U I O P \u00A8 \u00A3",
//     "{lock} Q S D F G H J K L M % \u00B5 {enter}",
//     "{shift} > W X C V B N ? . / \u00A7 {shift}",
//     '.com @ {space}'
//   ]
// }

const numpad = {
  default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
  shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
}

class LoginPage extends PureComponent {
  state = {
    layoutName: "default"
  };

  componentDidMount() {
    const { getListUsers } = this.props
    getListUsers();
  }

  onChange = input => {
    const { change } = this.props;
    change('password', input)
  };

  onKeyPress = button => {
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;
    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = event => {
    let input = event.target.value;
    this.keyboard.setInput(input);
  };

  render() {
    const { handleSubmit, enabledSubmit, submitLogin, listFilterUsers,to } = this.props
    const fields = <>
      <Field
        name="username"
        key="username"
        component={autocompletefield}
        label="نام کاربری"
        className={styles.formControl}
        validate={[]}
        list={listFilterUsers}
      />
      <Field
        name="password"
        key="password"
        component={textLoginPassword}
        label="گذرواژه"
        type="text"
        onChange={e => this.onChangeInput(e)}
        validate={[requiredpassword]}
      />
    </>
    return (
      <div className={styles.container}>
        <Fab size="small" color="primary" className={styles.btn} aria-label="add" onClick={()=>{to('/config')}}>
          <SettingsIcon />
        </Fab>
        <div className={styles.main}>
          <AuthForm
            fields={fields}
            handleSubmit={handleSubmit}
            enabledSubmit={enabledSubmit}
            onSubmit={submitLogin}
            submitText='ورود' />

          <div className={styles.keypadcontainer} style={{ direction: "ltr" }}>
            <KeyboardReact
              keyboardRef={r => (this.keyboard = r)}
              layoutName={this.state.layoutName}
              className={styles.keypad}
              layout={numpad}
              theme={"hg-theme-default hg-layout-numeric numeric-theme " + styles.keypad}
              onChange={input => this.onChange(input)}
              onKeyPress={button => this.onKeyPress(button)}
            />
          </div>
        </div>


      </div>
    )
  }
}

const formName = 'login'
export default withStyles(styles)(connectTo(
  state => ({
    enabledSubmit: isValid(state, formName),
    initialValues: { username: "", password: "" },

    listFilterUsers: state.baseinfo.ListUsers
  }),
  { submitLogin, getListUsers,to },
  reduxForm({ form: formName, enableReinitialize: true })(LoginPage)))
