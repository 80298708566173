import { createReducer } from 'redux-act'

import * as a from '../_actions/sidebar'

export const getDefaultState = _ => ({
  stateReceived: {
    SidebarLeftOpen: false,
    SidebarRightOpen: false,
    customerId:null,
  }
})


export default _ =>
  createReducer(
    {
      [a.triggerSidebarLeft]: (state,{customerId}) => ({ ...state, SidebarLeftOpen:!state.SidebarLeftOpen,customerId }),
      [a.triggerSidebarRight]: (state) => ({ ...state, SidebarRightOpen:!state.SidebarRightOpen, }), 
    },
    getDefaultState()
  )
