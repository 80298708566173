import {
    createReducer
} from 'redux-act'

import * as a from '../_actions/confirm'

const getDefaultState = _ => ({
    dialogContent:null,
    dialogTitle:null,
    onOK:()=>{},
    onCancel:()=>{},
    okText:"تایید",
    cancelText:"انصراف",
})

export default _ =>
    createReducer({
            [a.openConfirm]: (state, { dialogContent, dialogTitle, onOK,onCancel,okText,cancelText }) => {
            return({
                ...state,
                dialogContent, 
                dialogTitle, 
                onOK,
                onCancel,
                okText,
                cancelText
            })},
            [a.closeConfirm]: () => ({})
        },
        getDefaultState()
    )
