import { createAction } from 'redux-act'

export const startApp = createAction()
export const appStarted = createAction()
export const changePageSize = createAction()

export const changeRowsPerPage= createAction()
export const toggleSnackbar = createAction()

export const changeLanguage = createAction()


