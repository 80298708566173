import { isEmpty } from "./generic"

/* global localStorage:true */

export const configIn = () => {
  return !isEmpty(localStorage.getItem('modelConfig'))
}

export const loggedIn = () => {
  const expirationTime = localStorage.getItem('TokenExpirationTime')
  return new Date().getTime() / 1000 < parseInt(expirationTime, 10)
}

export const agencyIn = () => {
  const expirationTime = localStorage.getItem('TokenExpirationTime')
  return (new Date().getTime() / 1000 < parseInt(expirationTime, 10) && localStorage.getItem('IDAgency'))
}

export const unlogin = () => {
  localStorage.removeItem('Token')
  localStorage.removeItem('TokenExpirationTime')
}