import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-datepicker2/dist/react-datepicker2.min.css';
import 'leaflet/dist/leaflet.css'
import './index.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faUserCircle, faTrashAlt, faCubes, faFileAlt, faAngleRight, faAngleLeft, faFileUpload
    , faCheckCircle, faExclamationCircle, faBars, faArrowRight, faArrowLeft, faDiceD6, faDraftingCompass,
    faUsers, faWarehouse, faUniversity, faChartPie, faEnvelope, faAngleDown, faAngleUp, faHome, faAngleDoubleLeft, faInfo, faPencilAlt, faCaretDown,
    faPlus, faSearch, faFileExcel, faSave, faLongArrowAltLeft, faShoppingCart, faMapMarkerAlt, faHistory, faReceipt
} from '@fortawesome/free-solid-svg-icons'

import { faSquare, faCheckSquare, faMinusSquare, faFrown, faClock } from '@fortawesome/free-regular-svg-icons'

library.add(fab, faUserCircle, faTrashAlt, faCubes, faFileAlt, faSquare, faCheckSquare, faMinusSquare,
    faAngleRight, faAngleLeft, faFileUpload, faCheckCircle, faExclamationCircle, faBars, faArrowRight, faArrowLeft,
    faDiceD6, faDraftingCompass, faUsers, faWarehouse, faUniversity, faChartPie, faEnvelope, faAngleDown, faAngleUp, faHome, faFrown, faAngleDoubleLeft, faInfo, faPencilAlt, faCaretDown,
    faPlus, faSearch, faFileExcel, faSave, faLongArrowAltLeft, faShoppingCart, faMapMarkerAlt, faClock, faHistory, faReceipt)

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
