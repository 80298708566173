import { takeLatest } from 'redux-saga/effects'

import * as baseinfoActions from '../_actions/baseInfo/baseinfo'
import * as baseinfoSagas from './baseInfo/baseinfo'

import * as ratingActions from '../_actions/baseInfo/rating'
import * as ratingSagas from './baseInfo/rating'

import * as genericActions from '../_actions/generic'
import * as genericSagas from './generic'

import * as authActions from '../_actions/auth'
import * as authSagas from './auth'

import * as uploadActions from '../_actions/upload'
import * as uploadSagas from './upload'

export default function* saga() {
  const relations = [
    [baseinfoActions, baseinfoSagas],
    [ratingActions, ratingSagas],

    [genericActions, genericSagas],
    [uploadActions, uploadSagas],
    [authActions, authSagas],
  ]

  for (const [actions, sagas] of relations) {
    for (const [actionName, action] of Object.entries(actions)) {
      const saga = sagas[actionName]
      if (saga) yield takeLatest(action.getType(), saga)
    }
  }
}
