import { call, put, select, take } from 'redux-saga/effects'
import { unauthorizeUser } from '../_actions/auth'
import { toggleSnackbar } from '../_actions/generic'
import { SubmissionError } from 'redux-form'

export function* callWith401Handle(...args) {
  if (process.env.REACT_APP_MOCK) return
  try {
    const data = yield call(...args)
    if (data.msg != null)
      throw new Error({ message: data.msg });
    else if (data.error != null)
      throw new Error({ message: data.error });

    return data
  } catch (err) {
    if (err.status === 401) {
      yield put(unauthorizeUser())
      throw new Error({ message: "" });
    }
    else if (err.status === 404) {
      throw new Error({ message: err.message });
    }
    else {
      throw new Error({ message: err.message });
    }
  }
}

export function* waitFor(selector) {
  if (yield select(selector)) return; // (1)

  while (true) {
    yield take('*'); // (1a)
    if (yield select(selector)) return; // (1b)
  }
}

export function* rejectRequest(reject, message) {
  if (message !== null && message !== undefined && message !== "")
    yield put(toggleSnackbar(message))
  if (reject !== null && reject !== undefined)
    yield call(reject, new SubmissionError(message))
}

export function* resolveRequest(resolve, message, model) {
  if (message !== null && message !== undefined && message !== "")
    yield put(toggleSnackbar(message))
  if (resolve !== null && resolve !== undefined)
    yield call(resolve, model)
}