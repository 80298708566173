import React from 'react'
import { put, select, call } from 'redux-saga/effects'
import { get, getText, post } from '../../_utils/api'
import { callWith401Handle, waitFor } from '../api'
import {
  GETBYTEL, INSERTBYTEL, GETUSERS, WELLCOMEINFO, PRODUCTGROUPS, PRODUCTS, DAILYPRODUCTS,
  INVOICEINSERT, GETINVOICE, RECIVEIDENTIFIER, STARTPAYMENT, GETSLIDERID,
  GETBYIDINVOICE, GETBYINVOICENUMBER, GETBYORDERNO, GETCOMMENTSAMPLE, INSERTRATING
} from '../../_constants/api';
import * as actions from '../../_actions/baseInfo/baseinfo';
import { toggleSnackbar } from '../../_actions/generic';
import { loadDialog } from '../../_actions/dialog';
import { unauthorizeUser } from '../../_actions/auth';

export function* insertRating({ payload: { comment } }) {
  try {
    const ratingProducts = yield select(state => state.baseinfo.ratingProducts)
    const retData = yield callWith401Handle(post, INSERTRATING, { Ratings: ratingProducts.map(m => ({ IDProduct: m.IDProduct, IDInvoice: m.IDInvoice, Rate: m.Rate, CommentSamples: m.CommentSamples, Comment: (m.IDProduct == null ? comment : "") })) })
    yield put(actions.receiveInsertRating(retData))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* getByIdInvoice({ payload: { value } }) {
  try {
    const retData = yield callWith401Handle(get, GETBYIDINVOICE(value), {})

    if (retData.Enable) {
      const allProducts = yield select(state => state.baseinfo.allProducts)
      const ratingProducts = allProducts.filter(f => retData.ProductIdList.includes(f.ID)).map(m => ({
        ...m,
        IDProduct: m.ID,
        IDInvoice: retData.IDInvoice,
        Rate: 3,
        IDPerson: retData.IDPerson,
        Comment: "",
        CommentSamples: []
      }))

      const retDataComment = yield callWith401Handle(get, GETCOMMENTSAMPLE, {})
      yield put(actions.receiveCommentSample(retDataComment))

      yield put(actions.receiveInvoiceList([...ratingProducts, {
        ProductName: "",
        IDProduct: null,
        IDInvoice: retData.IDInvoice,
        Rate: 3,
        IDPerson: retData.IDPerson,
        Comment: "",
        CommentSamples: []
      }]))
    }
    else
      yield put(toggleSnackbar("شما قبلا در نظر سنجی شرکت  کرده اید"))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* getByInvoiceNumber({ payload: { value } }) {
  try {
    const retData = yield callWith401Handle(get, GETBYINVOICENUMBER(value), {})
    const allProducts = yield select(state => state.baseinfo.allProducts)
    const ratingProducts = allProducts.filter(f => retData.ProductIdList.includes(f.ID)).map(m => ({
      ...m,
      IDProduct: m.ID,
      IDInvoice: retData.IDInvoice,
      Rate: 5,
      IDPerson: retData.IDPerson,
      Comment: "",
      CommentSamples: []
    }))

    const retDataComment = yield callWith401Handle(get, GETCOMMENTSAMPLE, {})
    yield put(actions.receiveCommentSample(retDataComment))

    yield put(actions.receiveInvoiceList([...ratingProducts, {
      ProductName: "",
      IDProduct: null,
      IDInvoice: retData.IDInvoice,
      Rate: 5,
      IDPerson: retData.IDPerson,
      Comment: "",
      CommentSamples: []
    }]))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* getByOrderNo({ payload: { value } }) {
  try {
    const retData = yield callWith401Handle(get, GETBYORDERNO(value), {})
    const allProducts = yield select(state => state.baseinfo.allProducts)
    const ratingProducts = allProducts.filter(f => retData.ProductIdList.includes(f.ID)).map(m => ({
      ...m,
      IDProduct: m.ID,
      IDInvoice: retData.IDInvoice,
      Rate: 5,
      IDPerson: retData.IDPerson,
      Comment: "",
      CommentSamples: []
    }))

    const retDataComment = yield callWith401Handle(get, GETCOMMENTSAMPLE, {})
    yield put(actions.receiveCommentSample(retDataComment))

    yield put(actions.receiveInvoiceList([...ratingProducts, {
      ProductName: "",
      IDProduct: null,
      IDInvoice: retData.IDInvoice,
      Rate: 5,
      IDPerson: retData.IDPerson,
      Comment: "",
      CommentSamples: []
    }]))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* getByTel({ payload: { tel } }) {
  try {
    const retData = yield callWith401Handle(get, GETBYTEL(tel), {})
    yield put(actions.receiveUserInfo(retData))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* signout() {
  try {
    const signout = yield select(state => state.baseinfo.signout)
    if (signout > 10)
      yield put(unauthorizeUser())
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}


export function* insertByTel({ payload }) {
  try {
    const ID = yield callWith401Handle(post, INSERTBYTEL, payload)
    yield put(actions.receiveUserInfo({ ID: ID, ...payload }))

    yield put(actions.setShowPaymentTypeContainer(true))
    yield put(actions.setShowContact(false))
    yield put(actions.setShowPaymentType({ status: true, pager: null }))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* getListUsers() {
  try {
    const retData = yield callWith401Handle(get, GETUSERS, {})
    yield put(actions.receiveListUsers(retData.map(m => ({ id: m, value: m }))))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* selectType({ payload: { idOrderType } }) {
  yield put(actions.getProductgroup({ idOrderType }))
}

export function* getListBranch() {
  try {
    const retData = yield callWith401Handle(get, WELLCOMEINFO, null)
    yield put(actions.receiveListBranch(retData))
  }
  catch ({ message }) {
    console.log('J: Error saga getListBranch: ', message)
  }
}

export function* getWellComeInfo() {
  try {
    const retData = yield callWith401Handle(get, WELLCOMEINFO, null)
    yield put(actions.receiveWellComeInfo(retData))
  }
  catch ({ message }) {
    console.log('J: Error saga getWellComeInfo: ', message)
  }
}

export function* GetSlider() {
  try {
    const retData = yield callWith401Handle(get, GETSLIDERID, null)

    if (retData == null || retData.length === 0) {
      const allProducts = yield select(state => state.baseinfo.allProducts)
      yield put(actions.receiveGetSlider(allProducts.filter(f => f.IDPicture !== null).map(m => m.IDPicture)))
    }
    else
      yield put(actions.receiveGetSlider(retData))
  }
  catch ({ message }) {
    console.log('J: Error saga receiveGetSlider: ', message)
  }
}

export function* getProductgroup({ payload: { idOrderType } }) {
  try {
    const retData = yield callWith401Handle(get, PRODUCTGROUPS, { idProductType: 2 })
    yield put(actions.reciveProductgroup(retData))
    if (retData.length > 0)
      yield put(actions.getProducts({ idOrderType, idGroup: retData[0].ID }))
  }
  catch ({ message }) {
    console.log('J: Error saga getProductgroup: ', message)
  }
}

export function* getProducts({ payload: { idOrderType, idGroup } }) {
  try {
    yield call(waitFor, state => state.baseinfo.allProducts.length > 0);
    const allProducts = yield select(state => state.baseinfo.allProducts)
    const products = allProducts.filter(f => (f.IDOrderType === 0 ||
      ((f.IDOrderType === 1 || f.IDOrderType === 4) && idOrderType === 1) ||
      ((f.IDOrderType === 2 || f.IDOrderType === 3) && idOrderType === 2))
      && f.IDGroup === idGroup);
    yield put(actions.reciveProducts(products))
  }
  catch ({ message }) {
    console.log('J: Error saga getWellComeInfo: ', message)
  }
}

export function* getProduct({ payload: { productId } }) {
  yield put(actions.setShowProductCartContainer(true))
  yield put(actions.setShowProduct({ status: true, productId }))
  yield put(actions.setShowCart(true))
}

//for slider
export function* GetDailyProducts() {
  const retData = yield callWith401Handle(get, DAILYPRODUCTS, null)
  const retDataProducts = yield callWith401Handle(get, PRODUCTS, {})
  yield put(actions.reciveProductsAll(retDataProducts))
  if (retData.length > 0)
    yield put(actions.receiveDailyProducts(retDataProducts.filter(f => retData.filter(ff => ff === f.ID).length > 0)))
  else
    yield put(actions.receiveDailyProducts(retDataProducts))
  yield put(actions.GetSlider())
}

export function* Reset() {
  const retData = yield callWith401Handle(get, DAILYPRODUCTS, null)
  const retDataProducts = yield select(state => state.baseinfo.allProducts)
  if (retData.length > 0)
    yield put(actions.receiveDailyProducts(retDataProducts.filter(f => retData.filter(ff => ff === f.ID).length > 0)))
  else
    yield put(actions.receiveDailyProducts(retDataProducts))
}

export function* InvoiceInsert() {
  try {
    yield put(actions.setShowFactorContainer(true))
    yield put(actions.setShowCardStep({ type: null, status: false }))
    yield put(actions.setShowFactor(true))
    const state = yield select(state => state)

    let aaa = {
      IdCreator: state.auth.ID,
      IdOrderType: state.baseinfo.idOrderType,
      IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
      IdDepartment: state.auth.AppSetting.IdDepartment,
      InvoiceItems: state.baseinfo.BasketList.map(m => ({
        ID: 0,
        IDProduct: m.ID,
        IDStore: state.auth.AppSetting.IdStore,
        Quantity: m.count,
        IDInvoice: 0,
        Comment: m.Comment
      })),
      Payments: [{
        IdFund: state.auth.AppSetting.idbankaccount,
        IdTerminal: state.auth.AppSetting.IdTerminal,
        IdType: 11,
        Amount: state.baseinfo.BasketList.map(m => m.count * m.PriceAfterDiscount).reduce((a, b) => a + b, 0)
      }],
      DiscountPrice: 0,
      Comment: '',
      MoreDetails: state.baseinfo.MoreDetails,
      IdAccount: 0,
      PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
    }

    if (state.baseinfo.paymentType === 2)
      aaa = {
        IdCreator: state.auth.ID,
        IdOrderType: state.baseinfo.idOrderType,
        IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
        IdDepartment: state.auth.AppSetting.IdDepartment,
        InvoiceItems: state.baseinfo.BasketList.map(m => ({
          ID: 0,
          IDProduct: m.ID,
          IDStore: state.auth.AppSetting.IdStore,
          Quantity: m.count,
          IDInvoice: 0
        })),
        Payments: null,
        DiscountPrice: 0,
        Comment: '',
        MoreDetails: state.baseinfo.MoreDetails,
        IdAccount: 0,
        PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
      }
    const retData = yield callWith401Handle(post, INVOICEINSERT, aaa)

    if (retData.ResponseCode === 0) {
      try {
        const retData1 = yield callWith401Handle(getText, GETINVOICE(retData.ID), {})
        yield put(actions.receiveGetInvoice(retData1))
      }
      catch ({ message }) {
        yield put(loadDialog({
          dialogContent: <div>
            <span>مشکلی در چاپ فاکتور به وجود آمد به صندوق مراجعه نمایید.</span>
          </div>, dialogTitle: "", openDialog: true, closeDialog: () => { }, headerColor: null
        }))
        console.log("aaaaaaaaaa")
        yield call(waitFor, state => state.dialog.openDialog !== true);
        console.log("bbbbbbbbbbbbbb")
        yield put(actions.Reset())
        console.log("cccccccccccccccc")
      }
    }
    else {
      yield put(toggleSnackbar(retData.ResponseMessage))
    }
  }
  catch (vvvvv) {
    console.log("ssssssssssssssssssssssssss", vvvvv)
  }
}

export function* GetInvoice({ payload: { id } }) {
  const retData = yield callWith401Handle(get, GETINVOICE(id), null)
  yield put(actions.receiveGetInvoice(retData))
}

export function* setShowCardStep({ payload: { type, status } }) {
  if (status && type === 1) {
    //reciveidentifier
    const retData = yield callWith401Handle(post, RECIVEIDENTIFIER, null)

    //Startpayment
    const state = yield select(state => state)
    let amount = state.baseinfo.BasketList.map(m => m.count * m.PriceAfterDiscount).reduce((a, b) => a + b, 0)

    const retDataStartpayment = yield callWith401Handle(post, STARTPAYMENT, {
      "TerminalID": state.auth.AppSetting.TerminalNo,
      "Amount": amount,
      "AccountType": 0,
      "Additional": "test",
      "ResNum": "123456",
      "Identifier": retData.Data.Identifier,
      "TotalAmount": amount,
      "userNotifiable": {
        "FooterMessage": null,
        "PrintItems": [{
          "Item": "Mohsen",
          "Value": "Moghadam",
          "Alignment": 0,
          "ReceiptType": 2
        }]
      },
      "TransactionType": 0,
      "BillID": null,
      "PayID": null,
      "RefrenceData": null
    })
    console.log("hhhh",retDataStartpayment)
    if (retDataStartpayment.IsSuccess) {
      console.log("h1",retDataStartpayment)
      //InvoiceInsert()
      yield put(actions.setShowFactorContainer(true))
      console.log("h2",retDataStartpayment)
      // yield put(actions.setShowCardStep({ type: null, status: false }))
      console.log("h3",retDataStartpayment)
      yield put(actions.setShowFactor(true))
      console.log("h4",retDataStartpayment)
      const state = yield select(state => state)
      console.log("rrr",state.baseinfo)

      let aaa = {
        IdCreator: state.auth.ID,
        IdOrderType: state.baseinfo.idOrderType,
        IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
        IdDepartment: state.auth.AppSetting.IdDepartment,
        InvoiceItems: state.baseinfo.BasketList.map(m => ({
          ID: 0,
          IDProduct: m.ID,
          IDStore: state.auth.AppSetting.IdStore,
          Quantity: m.count,
          IDInvoice: 0
        })),
        Payments: [{
          IdFund: state.auth.AppSetting.idbankaccount,
          IdTerminal: state.auth.AppSetting.IdTerminal,
          IdType: 11,
          Amount: state.baseinfo.BasketList.map(m => m.count * m.PriceAfterDiscount).reduce((a, b) => a + b, 0),
          TerminalTransaction: {
            ID: 0,
            IDTerminal: retDataStartpayment.TerminalID,
            ErrorCode: retDataStartpayment.ErrorCode,
            RefrenceNo: retDataStartpayment.RRN,
            Amount: retDataStartpayment.Amount,
            TransactionDate: retDataStartpayment.CreateOn,
            CardNo: "0000",
            Stan: retDataStartpayment.State,
            Successful: retDataStartpayment.IsSuccess
          }
        }],
        DiscountPrice: 0,
        Comment: '',
        MoreDetails: state.baseinfo.MoreDetails,
        IdAccount: 0,
        PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
      }

console.log("aaa",aaa,state.baseinfo.paymentType)

      if (state.baseinfo.paymentType === 2)
        aaa = {
          IdCreator: state.auth.ID,
          IdOrderType: state.baseinfo.idOrderType,
          IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
          IdDepartment: state.auth.AppSetting.IdDepartment,
          InvoiceItems: state.baseinfo.BasketList.map(m => ({
            ID: 0,
            IDProduct: m.ID,
            IDStore: state.auth.AppSetting.IdStore,
            Quantity: m.count,
            IDInvoice: 0
          })),
          Payments: [],
          DiscountPrice: 0,
          Comment: '',
          MoreDetails: state.baseinfo.MoreDetails,
          IdAccount: 0,
          PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
        }


      const retDataInvoice = yield callWith401Handle(post, INVOICEINSERT, aaa)
      console.log("bbb",retDataInvoice)
      if (retDataInvoice.ResponseCode === 0) {
        try {
          const retData1 = yield callWith401Handle(getText, GETINVOICE(retDataInvoice.ID), {})
          console.log("ccc",retData1)
          yield put(actions.receiveGetInvoice(retData1))
        }
        catch ({ message }) {


          yield put(loadDialog({
            dialogContent: <div>
              <span>مشکلی در چاپ فاکتور به وجود آمد به صندوق مراجعه نمایید.</span>
            </div>, dialogTitle: "", openDialog: true, closeDialog: () => { }, headerColor: null
          }))
          yield call(waitFor, state => state.dialog.openDialog !== true);
          yield put(actions.Reset())
        }
      }
      else {
        yield put(toggleSnackbar(retDataInvoice.ResponseMessage))
      }
    }
    else {
      console.log("hhhh2222",retDataStartpayment)
      const showCardStep = yield select(state => state.baseinfo.showCardStep)
      if (showCardStep) {
        //error
        yield put(loadDialog({
          dialogContent: <div>
            <span>عملیات با خطا مواجه شد مجدد تلاش کنید.</span>
          </div>, dialogTitle: retDataStartpayment.ErrorDescription, openDialog: true, closeDialog: () => { }, headerColor: null
        }))
        yield call(waitFor, state => state.dialog.openDialog !== true);



        // if (AppSetting.EnableCashPay && AppSetting.EnableCardPay) {
        //   setShowPaymentTypeContainer(true);
        //   setShowCardStep({ type: 1, status: false });
        //   setShowPaymentType({ status: true, pager: null });
        // }
        // else if (AppSetting.EnableTel) {
        //   // setShowPaymentType({ status: true, pager: this.state.pager })
        //   setShowContactContainer(true);
        //   setShowCardStep({ type: 1, status: false });
        //   setShowContact(true);
        // }
        // else if (AppSetting.EnablePager && idOrderType === 1) {
        //   setShowCardStep({ type: 1, status: false });
        //   setShowPagerContainer(true);
        //   setShowPager(true);
        // }
        // else {
        //   setShowCardStep({ type: 1, status: false });
        //   setShowCart(true);
        //   setShowProductCartContainer(true);
        // }




      }


      // yield put(actions.setShowPaymentTypeContainer(true));
      // yield put(actions.setShowPaymentType({ status: true, pager: null }));
      // yield put(actions.setShowCardStep({ type: 2, status: false }));
    }
  }
}
