import { createReducer } from 'redux-act'
import * as a from '../_actions/generic'

const getDefaultState = () => ({
  pageWidth: window.innerWidth,
  pageHeight: window.innerHeight,
  mouseX: 0,
  mouseY: 0,
  snackbarMessage: '',
  rowsPerPage: 10,

  copyRight: '',
  copyRightLink: '',
  appStarted: false,

  language: 'fa',

})

export default () =>
  createReducer(
    {
      [a.changePageSize]: (state, { width, height }) => ({
        ...state,
        pageWidth: width,
        pageHeight: height
      }),
      [a.changeRowsPerPage]: (state, { rowsPerPage }) => {
        return ({
          ...state,
          rowsPerPage
        })
      },
      [a.toggleSnackbar]: (state, snackbarMessage) => ({ ...state, snackbarMessage }),

      [a.appStarted]: (state) => {
        return ({
          ...state,
          appStarted: true
        })
      },

      [a.changeLanguage]: (state, model) => {
        console.log("model",model)
        if (model === 'fa')
          document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
        else
          document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");

        return ({
          ...state,
          language: model
        })
      },

    },
    getDefaultState()
  )

