import React from 'react'
import styles from './autocompletefield.module.scss';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const AutocompleteFieldForRender = ({
  input,
  label,
  value,
  className,
  list,
  meta: { active, error, warning, touched },
  ...custom
}) => {
  return (
    <Autocomplete
      options={list}
      getOptionLabel={option => option.value}
      className={className}
      renderInput={params => (
        <TextField
          label={label}
          variant="outlined"
          {...params}
          inputProps={
            {
              ...params.inputProps,
              autoComplete: "off",
              form: {
                autocomplete: "off",
              },
            }
          }
          {...input}
          {...custom} fullWidth />
      )}
    />
  )
}

export default withStyles(styles)(AutocompleteFieldForRender)