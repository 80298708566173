
class RequestError {
  constructor(status, message) {
    this.status = status
    this.message = message
  }
}

export const headers = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('Token')}`,
})

export const makePostOptions = data => {
  return ({
    method: 'POST',
    //mode: 'cors',
    headers: headers(),
    body: JSON.stringify(data)
  })
}

export const makeUploadOptions = data => ({
  method: 'POST',
  mode: 'cors',
  headers: ({
    //'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('Token')}`
  }),
  body: data
})


const buildGetUrlWithParams = (url, parameters) => {

  let complateqs = "";

  const buildUrl = (Prifixkey, params) => {
    let qs = "";

    const sumf = key => (element, index) => {
      if (element instanceof Array) {
        element.forEach(sumf(key));
      }
      else if (element instanceof Object) {
        qs += buildUrl(encodeURIComponent(key) + "[" + index + "].", element)
      }
      else
        qs += Prifixkey + encodeURIComponent(key) + "[" + index + "]=" + encodeURIComponent(element) + "&";
    };

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = params[key];
        if (value instanceof Array) {
          value.forEach(sumf(key));
        }
        else if (value instanceof Object) {
          qs += buildUrl(encodeURIComponent(key) + ".", value)
        }
        else if (value !== null && value !== undefined) {
          qs += Prifixkey + encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
        }
      }
    }

    return qs;
  }
  complateqs = buildUrl("", parameters)
  if (complateqs.length > 0) {
    complateqs = complateqs.substring(0, complateqs.length - 1); //chop off last "&"
    url = url + "?" + complateqs;
  }

  return url;
}

export const makePatchOptions = data => ({
  ...makePostOptions(data),
  method: 'PATCH'
})

export const makePutOptions = data => ({
  ...makePostOptions(data),
  method: 'Put'
})

export const getOptions = () => ({
  method: 'GET',
  headers: headers(),
  // responseType: 'arraybuffer'
})

// export const makeGetOptions = () => ({
//   method: 'GET',
//   headers: headers(),
// })

export const deleteOptions = () => ({
  method: 'DELETE',
  mode: 'cors',
  headers: headers()
})

const request = (url, options) =>
  fetch(url, options).then(response => {
    const { status } = response
    if (status === 204) return {}

    if (status >= 200 && status < 400)
      return response.json()

    if (status === 401) {
      throw new RequestError(status, "Unauthorized")
    }

    return response.json().then(message => { throw new RequestError(status, message) })
  })

const requestText = (url, options) =>
  fetch(url, options).then(response => {
    const { status } = response
    if (status === 204) return {}

    if (status >= 200 && status < 400)
      return response.text()

    if (status === 401) {
      throw new RequestError(status, "Unauthorized")
    }

    return response.json().then(message => { throw new RequestError(status, message) })
  })

const requestDownload = (url, options) =>
  fetch(url, options).then(response => {
    const { status } = response
    if (status === 404) {
      const json = response.json()
      return json.then(message => { throw new RequestError(status, message) })
    }
    else {
      return (response.blob())
        .then(function (blob) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "result.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          return ''
        })
    }
  })

export const plainGet = url => request(url, { method: 'GET', header: { 'Content-Type': 'application/json' } })
export const plainPost = (url, data) => request(url, { method: 'POST', body: JSON.stringify(data) })

export const get = (url, params) => request(buildGetUrlWithParams(url, params), getOptions())
export const getText = (url, params) => requestText(buildGetUrlWithParams(url, params), getOptions())
export const post = (url, data) => request(url, makePostOptions(data))
export const patch = (url, data) => request(url, makePatchOptions(data))
export const put_method = (url, data) => request(url, makePutOptions(data))
export const del = (url, params) => request(buildGetUrlWithParams(url, params), deleteOptions())
export const upload = (url, data) => request(url, makeUploadOptions(data))
export const download = (url, params) => requestDownload(buildGetUrlWithParams(url, params), getOptions())