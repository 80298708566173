import React from 'react'
import {closeDialog} from '../../_actions/dialog'
import { connectTo } from '../../_utils/generic'
import { Dialog} from '@material-ui/core'
import styles from './dialogWrapper.module.scss'

class DialogWrapper extends React.Component {
  
  render() {
    const { dialogContent,dialogTitle,openDialog,closeDialog,headerColor} = this.props;
    if (!dialogContent) return null;

    return (
      <Dialog onClose={closeDialog!=null ? closeDialog : this.onClose()} open={openDialog} aria-labelledby="simple-dialog-title" className={styles.container} >
        {/* <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
        <div>
          {dialogContent}
        </div> */}
        <div className={styles.title} style={{backgroundColor:headerColor}}>{dialogTitle}</div>
        <div className={styles.content}>
          {dialogContent}
        </div>
      </Dialog>
    )
  }

  onClose(){

  }
}

export default connectTo(
  state => ({
    dialogContent:state.dialog.dialogContent,
    dialogTitle:state.dialog.dialogTitle,
    openDialog:state.dialog.openDialog,
    closeDialog:state.dialog.closeDialog,
    headerColor:state.dialog.headerColor,
  }),
  { closeDialog},
  DialogWrapper
)
