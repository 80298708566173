import React, { PureComponent } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { submitConfig } from '../../_actions/auth'
import { required } from '../../_validators/forms';
import { connectTo } from '../../_utils/generic';
import { isValid } from '../../_utils/forms'
import textfield from '../../_components/fields/textfield/textfield'
import AuthForm from '../../_components/authForm/authForm'
import styles from './config.module.scss';
import { withStyles } from '@material-ui/core/styles'
import KeyboardReact from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const requiredconfigWebservice = required("آدرس وب سرویس")
const requiredconfigPosAddress = required("آدرس پوز")
// const requiredconfigTizerAddress = required("آدرس تیزر")

const persian = {
  'default': [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} ض ص ث ق ف غ ع ه خ ح ج چ پ",
    "{lock} ش س ی ب ل ا ت ن م ک گ {enter}",
    "{shift} ظ ط ز ژ ر ذ د ئ و . / {shift}",
    '.com @ {space}'
  ],
  shift: [
    "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
    "{tab} \u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
    '{lock} \u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " {enter}',
    "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F {shift}",
    ".com @ {space}"
  ]
}

const english = {
  default: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
    ".com @ {space}"
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
    ".com @ {space}"
  ]
};
// const numpad = {
//   default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
//   shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
// }

class ConfigPage extends PureComponent {
  state = {
    layoutName: "default",
    language: "en",
    field: 'configWebservice',
  };

  componentDidMount() {

  }

  onChange = input => {
    const { change } = this.props;
    const { field } = this.state;
    change(field, input)
  };

  onKeyPress = button => {
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;
    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = (field, event) => {
    let input = event.target.value;
    this.keyboard.setInput(input);
    this.setState({
      field: field
    });
  };

  onChangeField = field => {
    const { configWebservice, configTizerAddress, configPosAddress } = this.props
    if (field === "configWebservice")
      this.keyboard.setInput(configWebservice);
    else if (field === "configPosAddress")
      this.keyboard.setInput(configPosAddress);
    else
      this.keyboard.setInput(configTizerAddress);

    this.setState({
      field: field
    });
  }

  handleChangeLanguage = (event, language) => {
    this.setState({
      language: language
    });
  };

  render() {
    const { handleSubmit, enabledSubmit, submitConfig } = this.props
    const fields = <>
      <Field
        name="configWebservice"
        key="configWebservice"
        component={textfield}
        className={this.state.field === "configWebservice" ? styles.active : ""}
        label="آدرس وب سرویس"
        style={{ direction: "ltr" }}
        type="text"
        onChange={e => this.onChangeInput("configWebservice", e)}
        onFocus={e => this.onChangeField("configWebservice")}
        validate={[requiredconfigWebservice]}
      />
      <Field
        name="configPosAddress"
        key="configPosAddress"
        component={textfield}
        className={this.state.field === "configPosAddress" ? styles.active : ""}
        label="آدرس پوز"
        style={{ direction: "ltr" }}
        type="text"
        onChange={e => this.onChangeInput("configPosAddress", e)}
        onFocus={e => this.onChangeField("configPosAddress")}
        validate={[requiredconfigPosAddress]}
      />
      {/* <Field
        name="configTizerAddress"
        key="configTizerAddress"
        component={textfield}
        label="آدرس تیزر"
        type="text"
        style={{ direction: "ltr" }}
        onChange={e => this.onChangeInput("configTizerAddress", e)}
        onFocus={e => this.onChangeField("configTizerAddress")}
        className={this.state.field === "configTizerAddress" ? styles.active : ""}
        validate={[requiredconfigTizerAddress]}
      /> */}
      
    </>
    return (
      <div className={styles.container}>
        <AuthForm
          fields={fields}
          handleSubmit={handleSubmit}
          enabledSubmit={enabledSubmit}
          onSubmit={submitConfig}
          submitText='ثبت'
        />

        <div className={styles.keypadcontainer} style={{ direction: "ltr" }}>
          <ToggleButtonGroup style={{ direction: "rtl" }} size="small" value={this.state.language} exclusive onChange={this.handleChangeLanguage}>
            <ToggleButton
              value="fa"
            >fa</ToggleButton>
            <ToggleButton
              value="en"
            >en</ToggleButton>
          </ToggleButtonGroup>
          <KeyboardReact
            keyboardRef={r => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            className={styles.keypad}
            layout={this.state.language === "fa" ? persian : english}
            // theme={"hg-theme-default hg-layout-numeric numeric-theme " + styles.keypad}
            onChange={input => this.onChange(input)}
            onKeyPress={button => this.onKeyPress(button)}
          />
        </div>
      </div >
    )
  }
}

const formName = 'login'
const selector = formValueSelector(formName)
export default withStyles(styles)(connectTo(
  state => ({
    enabledSubmit: isValid(state, formName),
    initialValues: {
      configWebservice: state.auth.modelConfig != null ? state.auth.modelConfig.configWebservice : "",
      // configTizerAddress: state.auth.modelConfig != null ? state.auth.modelConfig.configTizerAddress : '',
      configPosAddress: state.auth.modelConfig != null ? state.auth.modelConfig.configPosAddress : '91.240.180.189:8024',
    },
    configWebservice: selector(state, 'configWebservice'),
    // configTizerAddress: selector(state, 'configTizerAddress'),
    configPosAddress: selector(state, 'configPosAddress'),
  }),
  { submitConfig },
  reduxForm({ form: formName, enableReinitialize: true })(ConfigPage)))
