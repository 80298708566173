import { createAction } from 'redux-act'

export const triggerSidebarLeft = createAction()
export const triggerSidebarRight = createAction()








