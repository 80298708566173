import { createReducer } from 'redux-act'

import * as a from '../../_actions/baseInfo/baseinfo'
import { takeIfExists } from '../../_utils/localStorage'

const getDefaultState = _ => ({
    reseting: false,
    lastRequest: null,
    ListUsers: [],
    ListBranch: [],
    WellComeInfo: null,

    showTizer: true,//true
    showTopSlider: false,//false

    showWellcomeSlider: true,//true
    showTouch: true,//true
    showSelectType: false,//false
    showTouchTypeContainer: true,//true
    showIdentity: true,//true
    showStartContainer: true,//true

    showGroups: false,//false
    showProducts: false,//false
    showProductsGroupsContainer: false,//false

    showProductCartContainer: false,//false
    showProduct: false,//false
    showCart: false,//false

    showPagerContainer: false,//false
    showPager: false,//false

    showContactContainer: false,//false
    showContact: false,//false

    showRatingContainer: false,//false
    showRating: false,//false

    showPaymentTypeContainer: false,//false
    showPaymentType: false,//false

    showCardStepContainer: false,//false
    showCardStep: false,//false

    showFactorContainer: false,//false
    showFactor: false,//false

    selectedProduct: null,
    selectedProductCount: null,

    paymentType: 2,
    pager: null,
    idOrderType: 1,
    productModel: null,
    MoreDetails: "",

    allProducts: [],
    dailyProducts: [],
    products: [],
    sliders: [],
    productGroups: [],
    sliderImages: [],

    ratingProducts: null,
    commentSamples: [],
    resultRating: null,

    BasketList: takeIfExists('basket', Object) || [],
    basket: {
        list: [],
    },
    userInfo: '',
    factorData: '',

    signout:0
})

export default _ =>
    createReducer({
        [a.changeRating]: (state, model) => {
            if (model.IDProduct)
                return ({
                    ...state,
                    ratingProducts: [...state.ratingProducts.filter(f => f.IDProduct !== model.IDProduct), model]
                })
            else
                return ({
                    ...state,
                    ratingProducts: [...state.ratingProducts.filter(f => f.IDProduct !== null), model]
                })
        },
        [a.receiveInsertRating]: (state, model) => ({
            ...state,
            resultRating: model
        }),
        [a.receiveInvoiceList]: (state, model) => ({
            ...state,
            ratingProducts: model
        }),
        [a.receiveCommentSample]: (state, model) => ({
            ...state,
            commentSamples: model
        }),
        [a.signout]: (state) =>{
          return  ({
                ...state,
                signout: state.signout+1
            })
        } ,
        [a.ResetTimer]: (state) => ({
            ...state,
            lastRequest: new Date().getTime()
        }),
        [a.Reset]: (state) => {
            return ({
                ...getDefaultState(),
                reseting: true,
                dailyProducts: state.dailyProducts,
                allProducts: state.allProducts,
                productGroups: state.productGroups,
                sliders: state.sliders,
                selectedProduct: state.selectedProduct,
                products: state.products,
                BasketList: [],
            })
        },
        [a.ResetDone]: (state) => {
            return ({
                ...state,
                reseting: false,
            })
        },
        [a.getListUsers]: (state) => ({
            ...state,
        }),
        [a.receiveListUsers]: (state, model) => ({
            ...state,
            ListUsers: model,
        }),
        [a.getListBranch]: (state) => ({
            ...state,
        }),
        [a.changeFactorDescription]: (state, model) => ({
            ...state,
            MoreDetails: model,
        }),
        [a.receiveListBranch]: (state, model) => ({
            ...state,
            ListBranch: model,
        }),
        [a.reciveProductsAll]: (state, model) => ({
            ...state,
            allProducts: model,
        }),
        [a.reciveProducts]: (state, model) => ({
            ...state,
            products: model,
        }),
        [a.receiveDailyProducts]: (state, model) => ({
            ...state,
            dailyProducts: model,
        }),
        [a.receiveGetSlider]: (state, model) => ({
            ...state,
            sliders: model
        }),
        [a.receiveSliderImages]: (state, model) => ({
            ...state,
            sliderImages: model,
        }),
        [a.reciveProductgroup]: (state, model) => ({
            ...state,
            productGroups: model,
        }),
        [a.getWellComeInfo]: (state) => ({
            ...state,
        }),
        [a.receiveWellComeInfo]: (state, model) => ({
            ...state,
            WellComeInfo: model,
        }),
        [a.setShowTizer]: (state, model) => ({
            ...state,
            showTizer: model,
        }),
        [a.setShowTopSlider]: (state, model) => ({
            ...state,
            showTopSlider: model,
        }),
        [a.setShowWellcomeSlider]: (state, model) => ({
            ...state,
            showWellcomeSlider: model,
        }),
        [a.setShowTouch]: (state, model) => ({
            ...state,
            showTouch: model,
        }),
        [a.setShowSelectType]: (state, model) => ({
            ...state,
            showSelectType: model,
        }),
        [a.setShowTouchTypeContainer]: (state, model) => ({
            ...state,
            showTouchTypeContainer: model,
        }),

        [a.setShowIdentity]: (state, model) => ({
            ...state,
            showIdentity: model,
        }),
        [a.setShowStartContainer]: (state, model) => ({
            ...state,
            showStartContainer: model,
        }),
        [a.setShowPager]: (state, model) => ({
            ...state,
            showPager: model,
        }),
        [a.setShowPagerContainer]: (state, model) => ({
            ...state,
            showPagerContainer: model,
        }),
        [a.setShowContact]: (state, model) => ({
            ...state,
            showContact: model,
        }),
        [a.setShowContactContainer]: (state, model) => ({
            ...state,
            showContactContainer: model,
        }),
        [a.setShowRating]: (state, model) => ({
            ...state,
            showRating: model,
        }),
        [a.setShowRatingContainer]: (state, model) => ({
            ...state,
            showRatingContainer: model,
        }),
        [a.receiveUserInfo]: (state, model) => ({
            ...state,
            userInfo: model,
        }),
        [a.setShowProducts]: (state, model) => ({
            ...state,
            showProducts: model,
        }),
        [a.setShowGroups]: (state, model) => ({
            ...state,
            showGroups: model,
        }),
        [a.setShowProductsGroupsContainer]: (state, model) => ({
            ...state,
            showProductsGroupsContainer: model,
        }),

        [a.setShowProduct]: (state, { status, productId }) => {
            var old = state.BasketList.filter(f => f.ID === productId);
            return ({
                ...state,
                showProduct: status,
                selectedProduct: productId,
                selectedProductCount: old.length > 0 ? old[0].count : 0,
            })
        },
        [a.addSelectedProduct]: (state) => {
            return ({
                ...state,
                selectedProductCount: state.selectedProductCount + 1,
            })
        },
        [a.removeSelectedProduct]: (state) => {

            return ({
                ...state,
                selectedProductCount: state.selectedProductCount > 0 ? state.selectedProductCount - 1 : 0,
            })
        },
        [a.setShowCart]: (state, model) => {

            return ({
                ...state,
                showCart: model,
            })
        },
        [a.setShowPager]: (state, model) => ({
            ...state,
            showPager: model,
        }),
        [a.setShowPagerContainer]: (state, model) => ({
            ...state,
            showPagerContainer: model,
        }),

        [a.setShowPaymentType]: (state, { status, pager }) => {
            return ({
                ...state,
                showPaymentType: status,
                pager: pager == null ? state.pager : pager,
            })
        },
        [a.setShowPaymentTypeContainer]: (state, model) => {
            return ({
                ...state,
                showPaymentTypeContainer: model,
            })
        },
        [a.setShowCardStep]: (state, { type, status }) => {
            return ({
                ...state,
                showCardStep: status,
                paymentType: type ? type : state.paymentType,
            })
        },
        [a.setShowCardStepContainer]: (state, model) => ({
            ...state,
            showCardStepContainer: model,
        }),
        [a.setShowFactor]: (state, model) => ({
            ...state,
            showFactor: model,
        }),
        [a.setShowFactorContainer]: (state, model) => ({
            ...state,
            showFactorContainer: model,
        }),
        [a.setShowProductCartContainer]: (state, model) => {
            return ({
                ...state,
                showProductCartContainer: state.showCart || state.showProduct || model,
            })
        },

        [a.backToProductList]: (state) => ({
            ...state,
            showCart: false,
            showProduct: false,
            // showPager: false,
            // showPaymentType: false,
            // selectedProduct: null
        }),

        [a.selectType]: (state, { idOrderType }) => ({
            ...state,
            idOrderType,
        }),
        [a.reciveSelectProduct]: (state, model) => ({
            ...state,
            productModel: model,
        }),

        [a.addToBasket]: (state, item) => {
            var old = state.BasketList.filter(f => f.ID === item.ID);
            return ({
                ...state,
                BasketList: [...state.BasketList.filter(f => f.ID !== item.ID), { ...item, Comment: "", count: old.length > 0 ? old[0].count + 1 : 1 }],
            })
        },
        [a.changeToBasket]: (state, item) => {
            var old = state.BasketList.filter(f => f.ID === item.ID);
            if (old && old.length > 0)
                return ({
                    ...state,
                    BasketList: [...state.BasketList.filter(f => f.ID !== item.ID), { ...item, count: old[0].count }],
                })
            else
                return ({
                    ...state,
                    BasketList: [...state.BasketList.filter(f => f.ID !== item.ID), { ...item, count: 0 }],
                })
        },
        [a.addRangeToBasket]: (state, { item, count }) => {
            if (count > 0)
                return ({
                    ...state,
                    BasketList: [...state.BasketList.filter(f => f.ID !== item.ID), { ...item, Comment: "", count }],
                })
            else
                return ({
                    ...state,
                    BasketList: [...state.BasketList.filter(f => f.ID !== item.ID)],
                })
        },

        [a.removeFromBasket]: (state, item) => {
            var old = state.BasketList.filter(f => f.ID === item.ID);
            if (old.length > 0) {
                if (old[0].count > 1)
                    return ({
                        ...state,
                        BasketList: [...state.BasketList.filter(f => f.ID !== item.ID), { ...item, Comment: "", count: old.length > 0 ? old[0].count - 1 : 0 }],
                    })
                else if (old[0].count === 1)
                    return ({
                        ...state,
                        BasketList: [...state.BasketList.filter(f => f.ID !== item.ID), { ...item, Comment: "", count: 0 }],
                    })
            }
            else return { ...state }
        },
        [a.clearFromBasket]: (state, item) => {
            return ({
                ...state,
                BasketList: state.BasketList.filter(f => f.productId !== item.ID),
            })
        },
        [a.resetBasket]: (state) => {
            return ({
                ...state,
                BasketList: [],
                basket: {
                    list: [],
                },
            })
        },
        [a.receiveBasket]: (state, { basket }) => ({
            ...state,
            basket,
        }),
        [a.receiveGetInvoice]: (state, factorData) => ({
            ...state,
            factorData,

        }),
    },
        getDefaultState()
    )