import React from 'react'
import { connectTo } from '../../_utils/generic'
import { Dialog, Button } from '@material-ui/core';
import { closeConfirm } from '../../_actions/confirm'
import styles from './confirmDialog.module.scss';

class confirmDialog extends React.Component {
  render() {
    const { dialogContent, dialogTitle, onOK, okText, cancelText,closeConfirm } = this.props;
    if (!dialogContent) return null;

    return (
      <Dialog onClose={()=>{ closeConfirm(); } } open={true} aria-labelledby="simple-dialog-title" className={styles.container} >
        <div className={styles.title}>{dialogTitle}</div>

        <div className={styles.content}>
          {dialogContent}
        </div>
        <div className={styles.actions}>
          <Button variant="outlined" color="primary" onClick={()=>{ onOK();closeConfirm(); }}>
            {okText ? okText : 'تایید'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={()=>{ closeConfirm(); } }>
            {cancelText ? cancelText : 'انصراف'}
          </Button>
        </div>
      </Dialog>
    )
  }
}

export default connectTo(
  state => ({
    dialogContent:state.confirm.dialogContent,
    dialogTitle:state.confirm.dialogTitle,
    onOK:state.confirm.onOK,
    onCancel:state.confirm.onCancel,
    okText:state.confirm.okText,
    cancelText:state.confirm.cancelText,
  }),
  { closeConfirm },
  confirmDialog
)
