import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { loggedIn, configIn } from '../_utils/auth'
import { agencyIn } from '../_utils/auth'
import { connectTo } from '../_utils/generic';
import { changeBackAddress } from '../_actions/auth'

export const MyRoute = connectTo(
    state => ({

    }),
    {},
    (({ component: Component, children: Children, role, canActivate: CanActivate, ...rest }) => {
        return (
            <Route {...rest} render={props => (
                CanActivate === "config" && configIn() ? (Children ? <Component {...props} >{Children}</Component> : <Component {...props} />) :
                    CanActivate === "auth" && loggedIn() ? (Children ? <Component {...props} >{Children}</Component> : <Component {...props} />) :
                        CanActivate === "agency" && agencyIn() ? (Children ? <Component {...props} >{Children}</Component> : <Component {...props} />) :
                            CanActivate === "" ? (Children ? <Component {...props} >{Children}</Component> : <Component {...props} />) :

                                CanActivate === "auth" ?
                                    <MyRedirect location={props.location} to={{ pathname: '/auth' }} /> :
                                    CanActivate === "agency" ?
                                        <MyRedirect location={props.location} to={{ pathname: '/agency' }} /> :
                                        <MyRedirect location={props.location} to={{ pathname: '/config' }} />
            )} />
        )
    })
)

class RedirectComponent extends PureComponent {
    componentDidMount() {
        if (this.props.location)
            this.props.changeBackAddress({ backAddress: this.props.location.pathname })
    }

    render() {
        const { ...rest } = this.props

        return (
            <Redirect {...rest} />
        )
    }
}

export const MyRedirect = (connectTo(
    state => ({
    }),
    { changeBackAddress },
    RedirectComponent))
