import { createAction } from 'redux-act'

export const openDialog = createAction()
export const closeDialog = createAction()
export const loadDialog = createAction()
export const removeDialog = createAction()

export const openMapDialog = createAction()
export const closeMapDialog = createAction()
export const changeMapDialog = createAction()







