import { createAction } from 'redux-act'
export const sleepChecker = createAction()

export const getListUsers = createAction()
export const receiveListUsers = createAction()

export const getListBranch = createAction()
export const receiveListBranch = createAction()

export const getWellComeInfo = createAction()
export const receiveWellComeInfo = createAction()

export const GetDailyProducts = createAction()
export const receiveDailyProducts = createAction()

export const GetSlider = createAction()
export const receiveGetSlider = createAction()

export const GetSliderImages = createAction()
export const receiveSliderImages = createAction()

export const setShowTizer = createAction()
export const setShowTopSlider = createAction()

export const setShowWellcomeSlider = createAction()
export const setShowTouch = createAction()
export const setShowSelectType = createAction()
export const setShowTouchTypeContainer = createAction()
export const setShowIdentity = createAction()
export const setShowStartContainer = createAction()

export const setShowPager = createAction()
export const setShowPagerContainer = createAction()

export const setShowContact = createAction()
export const setShowContactContainer = createAction()
export const getByTel = createAction()
export const receiveUserInfo = createAction()
export const insertByTel = createAction()

export const setShowRating = createAction()
export const setShowRatingContainer = createAction()
export const getByIdInvoice = createAction()
export const getByInvoiceNumber = createAction()
export const getByOrderNo = createAction()
export const receiveInvoiceList = createAction()
export const getCommentSample = createAction()
export const receiveCommentSample = createAction()
export const changeRating = createAction()

export const insertRating = createAction()
export const receiveInsertRating = createAction()

export const setShowPaymentType = createAction()
export const setShowPaymentTypeContainer = createAction()

export const setShowCardStep = createAction()
export const setShowCardStepContainer = createAction()

export const setShowFactor = createAction()
export const setShowFactorContainer = createAction()

export const setShowCart = createAction()
export const setShowProduct = createAction()
export const setShowProductCartContainer = createAction()

export const setShowGroups = createAction()
export const setShowProducts = createAction()
export const setShowProductsGroupsContainer = createAction()

export const selectType = createAction()
export const getProducts = createAction()
export const reciveProducts = createAction()
export const reciveProductsAll = createAction()
export const getProductgroup = createAction()
export const reciveProductgroup = createAction()
export const getProduct = createAction()
export const reciveSelectProduct = createAction()

export const InvoiceInsert = createAction()
export const GetInvoice = createAction()
export const receiveGetInvoice = createAction()

export const backToProductList = createAction()

//basket
export const addToBasket = createAction()
export const addRangeToBasket = createAction()
export const removeFromBasket = createAction()
export const clearFromBasket = createAction()
export const resetBasket = createAction()
export const changeToBasket = createAction()

export const addSelectedProduct = createAction()
export const removeSelectedProduct = createAction()

export const getBasket = createAction()
export const receiveBasket = createAction()

export const changeFactorDescription = createAction()


export const Reset = createAction()
export const ResetDone = createAction()
export const ResetTimer = createAction()

export const signout = createAction()
