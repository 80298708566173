import React from 'react'

import { put, select, call } from 'redux-saga/effects'

import { get, getText, post } from '../../_utils/api'
import { callWith401Handle, waitFor } from '../api'
import { GETBYTEL, INSERTBYTEL, GETUSERS, WELLCOMEINFO, PRODUCTGROUPS, PRODUCTS, DAILYPRODUCTS, INVOICEINSERT, GETINVOICE, RECIVEIDENTIFIER, STARTPAYMENT, GETSLIDERID } from '../../_constants/api';
import * as actions from '../../_actions/baseInfo/baseinfo';

import { toggleSnackbar } from '../../_actions/generic';
import { loadDialog } from '../../_actions/dialog';

export function* getByTel({ payload: { tel } }) {
  try {
    const retData = yield callWith401Handle(get, GETBYTEL(tel), {})
    yield put(actions.receiveUserInfo(retData))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* insertByTel({ payload }) {
  try {
    const retData = yield callWith401Handle(post, INSERTBYTEL, payload)
    yield put(actions.receiveUserInfo(retData))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* getListUsers() {
  try {
    const retData = yield callWith401Handle(get, GETUSERS, {})
    yield put(actions.receiveListUsers(retData.map(m => ({ id: m, value: m }))))
  }
  catch ({ message }) {
    yield put(toggleSnackbar("خطا در برقراری ارتباط با سرور"))
  }
}

export function* selectType({ payload: { idOrderType } }) {
  yield put(actions.getProductgroup({ idOrderType }))
}

export function* getListBranch() {
  try {
    const retData = yield callWith401Handle(get, WELLCOMEINFO, null)
    yield put(actions.receiveListBranch(retData))
  }
  catch ({ message }) {
    console.log('J: Error saga getListBranch: ', message)
  }
}

export function* getWellComeInfo() {
  try {
    const retData = yield callWith401Handle(get, WELLCOMEINFO, null)
    yield put(actions.receiveWellComeInfo(retData))
  }
  catch ({ message }) {
    console.log('J: Error saga getWellComeInfo: ', message)
  }
}

export function* GetSlider() {
  try {
    const retData = yield callWith401Handle(get, GETSLIDERID, null)

    if (retData == null || retData.length === 0) {
      const allProducts = yield select(state => state.baseinfo.allProducts)
      yield put(actions.receiveGetSlider(allProducts.filter(f => f.IDPicture !== null).map(m => m.IDPicture)))
    }
    else
      yield put(actions.receiveGetSlider(retData))
  }
  catch ({ message }) {
    console.log('J: Error saga receiveGetSlider: ', message)
  }
}

export function* getProductgroup({ payload: { idOrderType } }) {
  try {
    const retData = yield callWith401Handle(get, PRODUCTGROUPS, { idProductType: 2 })
    yield put(actions.reciveProductgroup(retData))
    if (retData.length > 0)
      yield put(actions.getProducts({ idOrderType, idGroup: retData[0].ID }))
  }
  catch ({ message }) {
    console.log('J: Error saga getProductgroup: ', message)
  }
}

export function* getProducts({ payload: { idOrderType, idGroup } }) {
  try {
    yield call(waitFor, state => state.baseinfo.allProducts.length > 0);
    const allProducts = yield select(state => state.baseinfo.allProducts)
    const products = allProducts.filter(f => (f.IDOrderType === 0 ||
      ((f.IDOrderType === 1 || f.IDOrderType === 4) && idOrderType === 1) ||
      ((f.IDOrderType === 2 || f.IDOrderType === 3) && idOrderType === 2))
      && f.IDGroup === idGroup)
    yield put(actions.reciveProducts(products))
  }
  catch ({ message }) {
    console.log('J: Error saga getWellComeInfo: ', message)
  }
}

export function* getProduct({ payload: { productId } }) {
  yield put(actions.setShowProductCartContainer(true))
  yield put(actions.setShowProduct({ status: true, productId }))
  yield put(actions.setShowCart(true))
}

//for slider
export function* GetDailyProducts() {
  const retData = yield callWith401Handle(get, DAILYPRODUCTS, null)
  const retDataProducts = yield callWith401Handle(get, PRODUCTS, {})
  yield put(actions.reciveProductsAll(retDataProducts))
  if (retData.length > 0)
    yield put(actions.receiveDailyProducts(retDataProducts.filter(f => retData.filter(ff => ff === f.ID).length > 0)))
  else
    yield put(actions.receiveDailyProducts(retDataProducts))
  yield put(actions.GetSlider())
}

export function* sleepChecker() {
  // const lastRequest = yield select(state => state.baseinfo.lastRequest)
  // while (true) {
  //   yield call(waitFor, state => new Date().getTime() - state.baseinfo.lastRequest > 3000);
  //   yield put(ResetAction())
  // }
}

export function* Reset() {
  const retData = yield callWith401Handle(get, DAILYPRODUCTS, null)
  const retDataProducts = yield select(state => state.baseinfo.allProducts)
  if (retData.length > 0)
    yield put(actions.receiveDailyProducts(retDataProducts.filter(f => retData.filter(ff => ff === f.ID).length > 0)))
  else
    yield put(actions.receiveDailyProducts(retDataProducts))
}

export function* InvoiceInsert() {
  try {
    yield put(actions.setShowFactorContainer(true))
    yield put(actions.setShowCardStep({ type: null, status: false }))
    yield put(actions.setShowFactor(true))
    const state = yield select(state => state)

    let aaa = {
      IdCreator: state.auth.ID,
      IdOrderType: state.baseinfo.idOrderType,
      IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
      IdDepartment: state.auth.AppSetting.IdDepartment,
      InvoiceItems: state.baseinfo.BasketList.map(m => ({
        ID: 0,
        IDProduct: m.ID,
        IDStore: state.auth.AppSetting.IdStore,
        Quantity: m.count,
        IDInvoice: 0,
        Comment: m.Comment
      })),
      Payments: [{
        IdFund: state.auth.AppSetting.idbankaccount,
        IdTerminal: state.auth.AppSetting.IdTerminal,
        IdType: 11,
        Amount: state.baseinfo.BasketList.map(m => m.count * m.PriceAfterDiscount).reduce((a, b) => a + b, 0)
      }],
      DiscountPrice: 0,
      Comment: '',
      MoreDetails: state.baseinfo.MoreDetails,
      IdAccount: 0,
      PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
    }

    if (state.baseinfo.paymentType === 2)
      aaa = {
        IdCreator: state.auth.ID,
        IdOrderType: state.baseinfo.idOrderType,
        IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
        IdDepartment: state.auth.AppSetting.IdDepartment,
        InvoiceItems: state.baseinfo.BasketList.map(m => ({
          ID: 0,
          IDProduct: m.ID,
          IDStore: state.auth.AppSetting.IdStore,
          Quantity: m.count,
          IDInvoice: 0
        })),
        Payments: null,
        DiscountPrice: 0,
        Comment: '',
        MoreDetails: state.baseinfo.MoreDetails,
        IdAccount: 0,
        PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
      }
    const retData = yield callWith401Handle(post, INVOICEINSERT, aaa)

    if (retData.ResponseCode === 0) {
      const retData1 = yield callWith401Handle(getText, GETINVOICE(retData.ID), {})
      yield put(actions.receiveGetInvoice(retData1))

    }
    else {
      yield put(toggleSnackbar(retData.ResponseMessage))
    }

  }
  catch (vvvvv) {
    console.log("ssssssssssssssssssssssssss", vvvvv)
  }
}

export function* GetInvoice({ payload: { id } }) {
  const retData = yield callWith401Handle(get, GETINVOICE(id), null)
  yield put(actions.receiveGetInvoice(retData))
}

export function* setShowCardStep({ payload: { type, status } }) {
  if (status && type === 1) {
    //reciveidentifier
    const retData = yield callWith401Handle(post, RECIVEIDENTIFIER, null)

    //Startpayment
    const state = yield select(state => state)
    let amount = state.baseinfo.BasketList.map(m => m.count * m.PriceAfterDiscount).reduce((a, b) => a + b, 0)

    const retDataStartpayment = yield callWith401Handle(post, STARTPAYMENT, {
      "TerminalID": state.auth.AppSetting.TerminalNo,
      "Amount": amount,
      "AccountType": 0,
      "Additional": "test",
      "ResNum": "123456",
      "Identifier": retData.Data.Identifier,
      "TotalAmount": amount,
      "userNotifiable": {
        "FooterMessage": null,
        "PrintItems": [{
          "Item": "Mohsen",
          "Value": "Moghadam",
          "Alignment": 0,
          "ReceiptType": 2
        }]
      },
      "TransactionType": 0,
      "BillID": null,
      "PayID": null,
      "RefrenceData": null
    })

    if (retDataStartpayment.IsSuccess) {
      //InvoiceInsert()
      yield put(actions.setShowFactorContainer(true))
      yield put(actions.setShowCardStep({ type: null, status: false }))
      yield put(actions.setShowFactor(true))

      let aaa = {
        IdCreator: state.auth.ID,
        IdOrderType: state.baseinfo.idOrderType,
        IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
        IdDepartment: state.auth.AppSetting.IdDepartment,
        InvoiceItems: state.baseinfo.BasketList.map(m => ({
          ID: 0,
          IDProduct: m.ID,
          IDStore: state.auth.AppSetting.IdStore,
          Quantity: m.count,
          IDInvoice: 0
        })),
        Payments: [{
          IdFund: state.auth.AppSetting.idbankaccount,
          IdTerminal: state.auth.AppSetting.IdTerminal,
          IdType: 11,
          Amount: state.baseinfo.BasketList.map(m => m.count * m.PriceAfterDiscount).reduce((a, b) => a + b, 0),
          TerminalTransaction: {
            ID: 0,
            IDTerminal: retDataStartpayment.TerminalID,
            ErrorCode: retDataStartpayment.ErrorCode,
            RefrenceNo: retDataStartpayment.RRN,
            Amount: retDataStartpayment.Amount,
            TransactionDate: retDataStartpayment.CreateOn,
            CardNo: "0000",
            Stan: retDataStartpayment.State,
            Successful: retDataStartpayment.IsSuccess
          }
        }],
        DiscountPrice: 0,
        Comment: '',
        MoreDetails: state.baseinfo.MoreDetails,
        IdAccount: 0,
        PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
      }

      if (state.baseinfo.paymentType === 2)
        aaa = {
          IdCreator: state.auth.ID,
          IdOrderType: state.baseinfo.idOrderType,
          IdConfirmState: state.baseinfo.paymentType === 1 ? 255 : 0,
          IdDepartment: state.auth.AppSetting.IdDepartment,
          InvoiceItems: state.baseinfo.BasketList.map(m => ({
            ID: 0,
            IDProduct: m.ID,
            IDStore: state.auth.AppSetting.IdStore,
            Quantity: m.count,
            IDInvoice: 0
          })),
          Payments: [],
          DiscountPrice: 0,
          Comment: '',
          MoreDetails: state.baseinfo.MoreDetails,
          IdAccount: 0,
          PrintKitchen: state.baseinfo.paymentType === 1 ? true : false
        }

      const retDataInvoice = yield callWith401Handle(post, INVOICEINSERT, aaa)

      if (retDataInvoice.ResponseCode === 0) {
        const retData1 = yield callWith401Handle(getText, GETINVOICE(retDataInvoice.ID), {})
        yield put(actions.receiveGetInvoice(retData1))
      }
      else {
        yield put(toggleSnackbar(retDataInvoice.ResponseMessage))
      }
    }
    else {
      //error
      yield put(loadDialog({
        dialogContent: <div>
          <span>عملیات با خطا مواجه شد مجدد تلاش کنید.</span>
        </div>, dialogTitle: retDataStartpayment.ErrorDescription, openDialog: true, closeDialog: () => { }, headerColor: null
      }))
      yield call(waitFor, state => state.dialog.openDialog !== true);
      yield put(actions.setShowPaymentTypeContainer(true));
      yield put(actions.setShowPaymentType({ status: true, pager: null }));
      yield put(actions.setShowCardStep({ type: 2, status: false }));
    }
  }
}